import {CircularProgress, Grid, IconButton, Tooltip, useTheme} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import EditOffIcon from '@mui/icons-material/EditOff';
import EditNoteIcon from '@mui/icons-material/EditNote';
import Student from '../../../model/Student';
import {useCustomTheme} from "../../../store/theme-context";

type Props = {
	student: Student;
	isAdmin?: boolean;
	editedStudent?: Student | null;
	inProgress: boolean;
	disabled?: boolean;
	handleSave: () => void;
	handleClose: () => void;
	handleEdit: () => void;
	handleEditStudent?: () => void;
};

const ActionButtons = ({
	student,
	isAdmin,
	editedStudent,
	disabled,
	inProgress,
	handleSave,
	handleClose,
	handleEdit,
	handleEditStudent
}: Props) => {
	const { palette } = useTheme();
	const { isHighContrast } = useCustomTheme();
	return disabled ? (
		<Tooltip title={'Nie można już wprowadzać zmian'}>
			<EditOffIcon
				color={'disabled'}
				fontSize={'small'}
			/>
		</Tooltip>
	) : editedStudent?.id === student.id ? (
		<Grid
			container
			flexWrap={'nowrap'}
			justifyContent={'center'}
			alignItems={'center'}
		>
			{inProgress ? (
				<CircularProgress size={30} />
			) : (
				<>
					<Tooltip title={'Zapisz'}>
						<IconButton
							color={'success'}
							onClick={handleSave}
							sx={{
								padding: 0,
								width: '50%',
							}}
						>
							<SaveIcon fontSize={'small'} />
						</IconButton>
					</Tooltip>
					<Tooltip title={'Anuluj'}>
						<IconButton
							color={'warning'}
							onClick={handleClose}
							sx={{
								padding: 0,
								width: '50%',
							}}
						>
							<CancelIcon fontSize={'small'} />
						</IconButton>
					</Tooltip>
				</>
			)}
		</Grid>
	) : (
		<>
			<Tooltip title={'Edytuj'}>
				<IconButton
					onClick={handleEdit}
					sx={{
						padding: 0,
						width: '100%',
						color: isHighContrast ? palette.primary.light : 'inherit'
					}}
				>
					<ModeEditIcon fontSize={'small'} />
				</IconButton>
			</Tooltip>
			{handleEditStudent && isAdmin &&
				<Tooltip title={'Edytuj dane'} style={{marginTop: '10px'}}>
					<IconButton
						onClick={handleEditStudent}
						sx={{
							padding: 0,
							width: '100%',
							color: isHighContrast ? palette.primary.light : 'inherit'
						}}
					>
						<EditNoteIcon fontSize={'small'} />
					</IconButton>
				</Tooltip>
			}
		</>
	);
};

export default ActionButtons;
