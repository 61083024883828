import {Typography, useTheme} from '@mui/material';
import { useContext } from 'react';
import CustomTable from './CustomTable';
import District from '../../model/District';
import { grey } from '@mui/material/colors';
import OlympicsContext from '../../store/olympics-context';

const DistrictsTable = () => {
	const { palette } = useTheme();
	const { districts } = useContext(OlympicsContext);

	const data = (input: any) => {
		return {
			columns: [{ header: 'Nazwa', accessor: 'name', width: '100%', align: 'left', bgColor: grey[100] }],

			rows: input
				? input.map((i: District) => {
						return {
							name: (
								<Typography
									variant='caption'
									fontWeight='medium'
								>
									{i.name}
								</Typography>
							),
						};
				  })
				: [],
		};
	};

	return (
		<>
			<CustomTable data={data(districts)} />
		</>
	);
};

export default DistrictsTable;
