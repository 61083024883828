import { Box, Grid, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import Wysiwyg from './UI/Wysiwyg';
// @ts-ignore
import { announcementsPages, newsPages } from '../store/fixed-routing';
import { Link } from 'react-router-dom';
import { getLineHighlight } from '../styles/theme';
import { cleanUpHtml } from '../utils/string-tools';

type Props = {
	item: any;
};

const NewsTile = ({ item }: Props) => {
	const { palette, typography } = useTheme();
	const matchesMinMobile = useMediaQuery('(max-width:340px)');

	return (
		<Box
			position={'relative'}
			component={'article'}
		>
			<Box
				display={'flex'}
				alignItems={'flex-end'}
				pb={'26px'}
				px={'24px'}
				height={'500px'}
				minWidth={matchesMinMobile ? '250px' : '300px'}
				sx={{
					background: `linear-gradient(180deg, rgba(29, 29, 27, 0.00) 63.22%, #1D1D1B 100%), url(${
						item.acf.image && item.acf.image.url
					}), lightgray 50% / cover no-repeat`,
					backgroundSize: 'cover',
				}}
			>
				<Box
					component={'section'}
					display={'inline-flex'}
					flexDirection={'column'}
					alignItems={'center'}
					gap={'32px'}
					width={'100%'}
				>
					{item.post_title && (
						<Typography
							variant={'subtitle1'}
							textAlign={'center'}
							fontWeight={'500'}
							color={palette.primary.light}
							textTransform={'uppercase'}
							dangerouslySetInnerHTML={{
								__html: cleanUpHtml(item.post_title),
							}}
						/>
					)}
					<Box
						style={{
							position: 'relative',
							zIndex: 2,
							textDecoration: 'none',
						}}
					>
						<Typography
							sx={{
								textDecoration: 'underline',
								textAlign: 'center',
								padding: 0,
								margin: 0,
								position: 'relative',
								zIndex: 5,
								'& *::before': {
									display: 'inline-block',
									content: "''",
									left: '-10px',
									right: '0',
									top: '0',
									bottom: '0',
									position: 'absolute',
									backgroundImage: `url(${getLineHighlight().long})`,
									backgroundRepeat: 'round',
									backgroundPosition: '0 0',
									height: '100%',
									width: 'calc(100% + 20px)',
									zIndex: -1,
								},
							}}
						>
							<span
								dangerouslySetInnerHTML={{
									__html: cleanUpHtml(item.post_title),
								}}
							/>
						</Typography>
					</Box>
				</Box>
			</Box>
			<Box
				display={'flex'}
				flexDirection={'column'}
				position={'absolute'}
				alignItems={'center'}
				left={0}
				right={'0'}
				top={'-36px'}
				height={'573px'}
				minWidth={matchesMinMobile ? '250px' : '300px'}
				zIndex={2}
				sx={{
					border: '0.5px solid black',
					borderColor: palette.primary.main,
					backgroundColor: palette.primary.light,
					filter: 'opacity(0)',
					transform: 'scale(90%)',
					'&:hover': {
						zIndex: 5,
						transform: 'scale(107%)',
						filter: 'opacity(1)',
					},
					transition: 'transform .15s ease-in-out, filter .15s ease-in-out',
				}}
			>
				{item.acf.image && (
					<Box
						component={'img'}
						src={item.acf.image.url}
						alt={item.acf.image.alt}
						height={'200px'}
						width={'100%'}
						sx={{
							objectFit: 'cover',
						}}
					/>
				)}
				<Box
					display={'flex'}
					flexDirection={'column'}
					alignItems={'center'}
					gap={'16px'}
					p={'16px 44px'}
					sx={{
						minHeight: 'calc(100% - 200px)',
						width: '100%',
					}}
				>
					{item.post_title && (
						<Typography
							variant={'h5'}
							textAlign={'center'}
							fontWeight={'500'}
							color={palette.primary.main}
							textTransform={'uppercase'}
							dangerouslySetInnerHTML={{
								__html: cleanUpHtml(item.post_title),
							}}
						/>
					)}
					{item.acf.description && (
						<Wysiwyg
							text={item.acf.description}
							fontWeight='400'
							variantSize={'subtitle2'}
							sx={{ color: palette.primary.main, textAlign: 'center' }}
						/>
					)}
					<Link
						to={(item.post_type === 'post' ? newsPages : announcementsPages) + '/' + item.post_name}
						title={item.post_title}
						style={{
							marginTop: 'auto',
							textDecoration: 'none',
						}}
					>
						<Grid
							justifySelf={'flex-end'}
							m={'0 auto'}
							fontSize={typography.subtitle2.fontSize}
							fontWeight={'400'}
							sx={{
								textDecoration: 'underline',
								position: 'relative',
								zIndex: 2,
								textAlign: 'center',
								'&::after': {
									display: 'inline-block',
									content: "''",
									left: '-10px',
									right: '0',
									top: '0',
									bottom: '0',
									position: 'absolute',
									backgroundImage: `url(${getLineHighlight().short})`,
									backgroundSize: '100% 80%',
									backgroundRepeat: 'no-repeat',
									backgroundPosition: 'center',
									width: 'calc(100% + 20px)',
									zIndex: -1,
								},
							}}
						>
							<div
								dangerouslySetInnerHTML={{
									__html: cleanUpHtml(item.post_title),
								}}
							/>
						</Grid>
					</Link>
				</Box>
			</Box>
		</Box>
	);
};

export default NewsTile;
