import { Button, CircularProgress, Dialog, DialogActions, DialogTitle, useTheme } from '@mui/material';
import {useCustomTheme} from "../../store/theme-context";

type Props = {
	close: () => void;
	inProgress: boolean;
	text: string | null;
	onSuccess: () => void;
};
const ConfirmationDialog = ({ close, onSuccess, text, inProgress }: Props) => {
	const { palette } = useTheme();
	const { isHighContrast } = useCustomTheme()
	return (
		<>
			<Dialog
				open={!!text}
				onClose={() => close()}
			>
				<DialogTitle sx={{ fontSize: '24px' }}>{text}</DialogTitle>
				<DialogActions>
					<Button
						sx={{
							color: isHighContrast ? palette.primary.light : palette.secondary.main,
						}}
						onClick={() => close()}
					>
						Anuluj
					</Button>
					<Button
						sx={{
							color: isHighContrast ? palette.primary.light : palette.success.main,
						}}
						onClick={() => onSuccess()}
					>
						{inProgress ? <CircularProgress /> : 'OK'}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default ConfirmationDialog;
