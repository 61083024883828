import React from 'react';

const HideInputIcon = ({isHighContrast}: any) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='16'
			height='17'
			viewBox='0 0 16 17'
			fill='none'
		>
			<path
				d='M8 4C3 4 1 8.5 1 8.5C1 8.5 3 13 8 13C13 13 15 8.5 15 8.5C15 8.5 13 4 8 4Z'
				stroke={isHighContrast ? '#fff' : '#3D506C'}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M8 11C9.38071 11 10.5 9.88071 10.5 8.5C10.5 7.11929 9.38071 6 8 6C6.61929 6 5.5 7.11929 5.5 8.5C5.5 9.88071 6.61929 11 8 11Z'
				stroke={isHighContrast ? '#fff' : '#3D506C'}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export default HideInputIcon;
