import React, { createContext, useContext, useState, ReactNode } from 'react';
import {
	createTheme,
	ThemeProvider,
	Theme,
	CssBaseline,
	PaletteColorOptions,
	GlobalStyles,
	Box,
	ThemeOptions
} from '@mui/material';
import {getTheme} from "../styles/theme";

type ThemeContextType = {
	fontSize: number;
	isHighContrast: boolean;
	toggleContrast: () => void;
	increaseFontSize: () => void;
	decreaseFontSize: () => void;
	resetFontSize: () => void;
	theme: Theme;
	currentTheme: string | undefined;
}

declare module "@mui/material/styles" {
	interface Palette {
		custom: {
			main: string,
			greenDarkText: string,
			greenDarkTextBlack: string;
			greenLightText: string,
			white: string,
			whiteText: string,
			grey: string,
			greenLightTextWhite: string,
		};
	}
	interface PaletteOptions {
		custom?: {
			main: string,
			greenDarkText: string,
			greenDarkTextBlack: string;
			greenLightText: string,
			white: string,
			whiteText: string,
			grey: string,
			greenLightTextWhite: string,
		};
	}
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const useCustomTheme = () => {
	const context = useContext(ThemeContext);
	if (!context) {
		throw new Error('useCustomTheme must be used within a ThemeProvider');
	}
	return context;
};

export const CustomThemeProvider = ({ children }: { children: ReactNode }) => {
	const [fontSize, setFontSize] = useState(18);
	const [isHighContrast, setIsHighContrast] = useState(false);
	const [currentTheme, setCurrentTheme] = useState(process.env.REACT_APP_NAME);

	const increaseFontSize = () => setFontSize((prev) => Math.min(prev + 2, 24));
	const decreaseFontSize = () => setFontSize((prev) => Math.max(prev - 2, 12));
	const resetFontSize = () => setFontSize(18);
	const toggleContrast = () => setIsHighContrast((prev) => !prev);

	const base = getTheme(currentTheme);

	const highContrastPalette = isHighContrast
		? {
			background: {
				default: '#000',
			},
			primary: {
				main: '#fff',
				light: '#000',
			},
			secondary: {
				// main: '#bc3766',
				main: '#fff',
				light: currentTheme === 'OLiJPSP' ? '#ffca00' : '#F07D00',
			},
			customColors: {
				main: '#F5F5F5',
				gray: '#000',
			},
		}
		: {};

	const dynamicTypography = {
		fontSize: fontSize,
		body1: {
			fontSize: `${fontSize}px`,
			fontWeight: 400,
			'@media (max-width: 900px)': {
				fontSize: `${fontSize * 1.22}px`,
			},
		},
		body2: {
			fontSize: `${fontSize * 0.78}px`,
			fontWeight: 400,
		},
		h1: {
			fontSize: `${fontSize * 2.17}px`,
			fontWeight: 500,
			'@media (max-width: 1200px)': {
				fontSize: `${fontSize * 1.78}px`,
			},
			'@media (max-width: 900px)': {
				fontSize: `${fontSize * 1.67}px`,
			},
		},
		h2: {
			fontSize: `${fontSize * 2}px`,
			textAlign: 'left' as const,
			fontWeight: 400,
		},
		h3: {
			fontSize: `${fontSize * 1.78}px`,
			fontWeight: 400,
			'@media (max-width: 600px)': {
				fontSize: `${fontSize * 1.33}px`,
			},
		},
		h4: {
			fontSize: `${fontSize * 1.22}px`,
			textAlign: 'left' as const,
			fontWeight: 400,
			'@media (max-width: 900px)': {
				fontSize: `${fontSize * 1.12}px`,
			},
		},
		h5: {
			fontSize: `${fontSize}px`,
			fontWeight: 300,
		},
		h6: {
			fontSize: `${fontSize * 0.78}px`,
			fontWeight: 300,
			textAlign: 'left' as const,
		},
		subtitle1: {
			fontSize: `${fontSize}px`,
			fontWeight: '500',
			'@media (max-width: 1200px)': {
				fontSize: `${fontSize * 1.45}px`,
			},
			'@media (max-width: 900px)': {
				fontSize: `${fontSize * 1.22}px`,
			},
			'@media (max-width: 600px)': {
				fontSize: `${fontSize}px`,
			},
		},
		subtitle2: {
			fontSize: `${fontSize * 0.89}px`,
			fontWeight: '400',
		},
		title: {
			fontSize: `${fontSize * 1.78}px`,
			fontWeight: '500',
		}
	};

	const mergedThemeOptions: ThemeOptions = {
		...base,
		palette: {
			...base.palette,
			...highContrastPalette,
		},
		typography: {
			...base.typography,
			...dynamicTypography,
		},
	};

	const theme = createTheme(mergedThemeOptions);

	return (
		<ThemeContext.Provider
			value={{ fontSize, isHighContrast, toggleContrast, increaseFontSize, decreaseFontSize, resetFontSize, currentTheme, theme }}
		>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<GlobalStyles
					styles={{
						body: {
							color: isHighContrast && '#fff' + '!important'
						}
					}}
				/>
				{children}
			</ThemeProvider>
		</ThemeContext.Provider>
	);
};
