import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { cleanUpHtml } from '../utils/string-tools';
import HighlightedTextWysiwyg from '../components/UI/HighlightedTextWysiwyg';
import BlockWrapper from '../components/BlockWrapper';
import theme from '../styles/theme';
import MainButton from '../components/UI/MainButton';
import Wysiwyg from '../components/UI/Wysiwyg';

type Props = {
	content: any;
};

const TextAndQuoteWithPhoto = ({ content }: Props) => {
	const { palette, breakpoints } = useTheme();
	const [randomQuote, setRandomQuote] = useState<any>();

	useEffect(() => {
		setRandomQuote(content.quote_with_text[Math.floor(Math.random() * content.quote_with_text.length)]);
	}, []);
	const cleanQuote = randomQuote !== undefined && cleanUpHtml(randomQuote.author_quote);

	const matches = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<BlockWrapper
			lgSize={12}
			xlSize={10}
			withPadding={matches}
			justify={!matches ? 'space-between' : 'center'}
			sx={{
				gap: '40px',
			}}
		>
			<Grid
				item
				display={'flex'}
				flexDirection={'column'}
				justifyContent={'flex-end'}
				alignItems={'center'}
				gap={{ xs: '40px', lg: '80px' }}
				ml={{ md: '40px', xl: 0 }}
				md={5}
				lg={5.5}
				order={{ xs: 2, md: 1 }}
			>
				{randomQuote !== undefined && (
					<Box
						zIndex={1}
						position={'relative'}
						mt={{ xs: '2%', md: '8%' }}
						maxWidth={{ xs: '100%', md: '70vw' }}
						display={'flex'}
						alignItems={{ xs: 'center', md: 'flex-end' }}
						flexDirection={'column'}
						ml={{ md: '35%', lg: '30%' }}
						mr={{ md: '-35%', lg: '-30%' }}
					>
						{content.quote_with_text.length > 0 && (
							<HighlightedTextWysiwyg
								text={cleanQuote}
								rotate={true}
								sx={{ fontWeight: '500' }}
								variantSize={matches ? 'h4' : 'h3'}
							/>
						)}
						<Typography
							variant={'body1'}
							color={palette.primary.main}
							fontWeight={'400'}
							sx={{
								transform: 'rotate(-4deg)',
								[breakpoints.down('md')]: {
									mt: '10px',
								},
							}}
						>
							{randomQuote.author && randomQuote.author}
						</Typography>
					</Box>
				)}
				<Grid
					item
					display={'flex'}
					justifyContent={'space-between'}
					position={'relative'}
					width={'100%'}
					sx={{
						[breakpoints.down('lg')]: {
							ml: '0',
						},
						[breakpoints.down('sm')]: {
							flexDirection: 'column-reverse',
						},
					}}
				>
					<Box
						alignSelf={'flex-end'}
						position={'relative'}
						sx={{
							[breakpoints.down('md')]: {
								width: '100%',
							},
						}}
						mb={content.links && '70px'}
					>
						{content.text && (
							<Wysiwyg
								text={content.text}
								fontWeight={'500'}
								variantSize={'h1'}
							/>
						)}
						{content.highlighted_text && (
							<HighlightedTextWysiwyg
								text={content.highlighted_text}
								rotate={false}
								sx={{ fontWeight: '500' }}
								variantSize={'h1'}
							/>
						)}
						{content.registrations && (
							<Box
								mt={'24px'}
								mb={'40px'}
							>
								<Typography
									variant={'h4'}
									display={'inline-block'}
									color={palette.primary.main}
									fontWeight={'500'}
								>
									{content.registrations.text && content.registrations.text}
								</Typography>{' '}
								<Typography
									variant={'h4'}
									display={'inline-block'}
									color={palette.primary.main}
									fontWeight={'500'}
								>
									{content.registrations.registrations_info && content.registrations.registrations_info}
								</Typography>
							</Box>
						)}
						<Box
							// position={{ xs: 'relative', md: 'absolute' }}
							width={'100%'}
							display={'flex'}
							justifyContent={{ xs: 'center', md: 'flex-start' }}
							alignItems={'flex-start'}
							flexWrap={'wrap'}
							gap={'24px'}
							// mb={'40px'}
							bgcolor={palette.primary.light}
							zIndex={1}
						>
							{content.links &&
								content.links.map((link: any, index: number) => {
									return (
										<MainButton
											key={'button-' + index}
											link={link.link}
											darkVersion={link.button_special_color}
										/>
									);
								})}
						</Box>
					</Box>
				</Grid>
			</Grid>
			{randomQuote !== undefined && (
				<Grid
					xs={10}
					md={5}
					lg={5.5}
					item
					order={{ xs: 1, md: 2 }}
					sx={{
						position: 'relative',
						display: 'flex',
						justifyContent: { xs: 'center', md: 'flex-end' },
					}}
				>
					<Box
						component={'img'}
						mb={'-10px'}
						src={randomQuote.author_photo && randomQuote.author_photo.url}
						alt={randomQuote.author_photo && randomQuote.author_photo.alt}
						width={{ xs: '100%', sm: '80%', md: '100%' }}
						alignSelf={'flex-end'}
						justifySelf={'flex-end'}
						sx={{
							objectFit: 'cover',
						}}
					/>
				</Grid>
			)}
		</BlockWrapper>
	);
};

export default TextAndQuoteWithPhoto;
