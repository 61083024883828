import BlockWrapper from '../components/BlockWrapper';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import Wysiwyg from '../components/UI/Wysiwyg';
import { getLineHighlight } from '../styles/theme';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { NavLink } from 'react-router-dom';
import NewsBlock from './NewsBlock';

type Props = {
	content: any;
};

const PostContent = ({ content }: Props) => {
	const { palette, typography } = useTheme();
	const r = content?.recommended;
	const recommended = r && {
		title: r.title,
		news:
			r.news && r.news !== ''
				? r.news.map((n: any) => ({
						post_title: n.post_title,
						post_name: n.post_name,
						post_type: n.post_type,
						acf: {
							content: n.content,
							date: n.date,
							description: n.description,
							image: n.image,
						},
				  }))
				: [],
	};

	return (
		<>
			<NavLink to={content.parent.link} title={'Wróć'}>
				<ArrowBackIosIcon
					sx={{
						marginTop: '60px',
						color: palette.primary.main,
						marginLeft: '40px',
					}}
				/>
			</NavLink>
			<BlockWrapper
				smSize={10}
				lgSize={6}
				xlSize={6}
				justify={'center'}
				withPadding={false}
				sx={{
					flexDirection: 'column',
					alignItems: 'flex-start',
					padding: { xs: '0 26px', md: '' },
					marginBottom: '104px',
				}}
			>
				<Box
					display='flex'
					flexDirection='column'
					alignItems='start'
					justifyContent='center'
				>
					<Wysiwyg
						text={content.description}
						fontWeight={'500'}
						sx={{
							fontSize: typography.subtitle1.fontSize,
							letterSpacing: '0.18px',
							marginLeft: '10px',
							'& ul': {
								padding: '',
							},
							'& li': {
								margin: '20px 0 20px -15px',
							},
							'& a': {
								color: palette.primary.main,
								textDecorationThickness: '1px',
							},
							'&::after': {
								display: 'inline-block',
								content: "''",
								left: '-10px',
								right: '0',
								top: '0',
								bottom: '0',
								position: 'absolute',
								backgroundImage: `url(${getLineHighlight().short})`,
								backgroundSize: '100% 80%',
								backgroundRepeat: 'no-repeat',
								backgroundPosition: 'center',
								width: 'calc(100% + 20px)',
								zIndex: -1,
							},
						}}
					/>
					<Typography
						component={'span'}
						variant={'title'}
						sx={{
							color: palette.primary.main,
							fontWeight: 500,
							width: '100%',
							fontAlign: 'left',
						}}
					>
						{content.post_title && content.post_title}
					</Typography>
					<Wysiwyg
						text={content.content}
						fontWeight={'400'}
						sx={{
							fontSize: typography.h5.fontSize,
							letterSpacing: '0.18px',
							'& *': { display: '', m: '20px auto' },
							'& ul': {
								padding: '',
							},
							'& li': {
								margin: '20px 0 20px -15px',
							},
							'& a': {
								color: palette.primary.main,
								textDecorationThickness: '1px',
							},
						}}
					/>
				</Box>
				<Grid
					item
					container
					justifyContent={'space-between'}
					sx={{
						borderTop: `1px solid ${palette.primary.main}`,
						paddingTop: '16px',
					}}
				>
					<Typography
						sx={{
							color: palette.primary.main,
						}}
					>
						{content.date}
					</Typography>
					{content.authors.length > 0 && (
						<Typography
							sx={{
								color: palette.primary.main,
							}}
						>
							Autor: {content.authors.join(', ')}
						</Typography>
					)}
				</Grid>
			</BlockWrapper>
			<NewsBlock
				content={recommended}
				highlightTitle
			/>
		</>
	);
};

export default PostContent;
