import { ChangeEvent, HTMLInputTypeAttribute, useContext, useEffect } from 'react';
import {TextField, TextFieldProps, useTheme} from '@mui/material';
import ManagedFormContext from './MangedForm';
import {useCustomTheme} from "../../store/theme-context";

export type ManagedFormFieldProps = TextFieldProps & {
	label: string;
	accessor: string;
	type?: HTMLInputTypeAttribute | 'zipCode';
	initialValue?: any;
	initialCorrect?: boolean;
	optional?: boolean;
	errorMessage?: string;
	min?: number;
	max?: number;
	disabled?: boolean;
};
const ManagedFormField = (props: ManagedFormFieldProps) => {
	const { palette } = useTheme();
	const { isHighContrast } = useCustomTheme();
	const { data, validated, setTextValue, setNumberValue, setZipCodeValue, setEmailValue, setInitialProperties } =
		useContext(ManagedFormContext);
	const type = props.type ? props.type : 'text';

	const DEFAULT_ERROR = `Uzupełnij prawidłwo pole: ${props.label}`;
	const error = props.errorMessage ? props.errorMessage : DEFAULT_ERROR;

	useEffect(() => {
		setInitialProperties(props.accessor, props.initialValue, error, !!props.optional, !props.initialCorrect);
	}, []);

	const handleChangeValue = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		if (props.disabled) {
			return;
		}
		const value = event.target.value;
		switch (type) {
			case 'phone':
			case 'text':
				setTextValue(props.accessor, value, props.max);
				break;
			case 'number':
				setNumberValue(props.accessor, value, props.min);
				break;
			case 'email':
				setEmailValue(props.accessor, value);
				break;
			case 'zipCode':
				setZipCodeValue(props.accessor, value);
				break;
			default:
				return;
		}
	};

	return (
		<>
			{data[props.accessor] && (
				<TextField
					{...props}
					value={data[props.accessor]?.value ? data[props.accessor]?.value : ''}
					error={validated && data[props.accessor].error && !props.optional}
					onChange={handleChangeValue}
					disabled={props.disabled}
					sx={{
						color: isHighContrast ? palette.primary.light : 'inherit',
						'& input, & textarea': {
							color: isHighContrast ? palette.primary.light : 'inherit',
						},
						'& label': {
							color: isHighContrast ? palette.primary.light + '!important' : 'inherit',
						},
						'& fieldset': {
							borderColor: isHighContrast ? palette.primary.light + '!important' : 'inherit',
						},
						'& .MuiOutlinedInput-root': {
							'& fieldset': {
								borderRadius: '4px',
								borderColor: isHighContrast ? palette.primary.light : 'inherit',
							},
						},
						'& .MuiOutlinedInput-root:hover': {
							'& fieldset': {
								borderRadius: '4px',
								borderColor: isHighContrast ? palette.primary.light : 'inherit',
							},
						}
					}}
				/>
			)}
		</>
	);
};

export default ManagedFormField;
