import React, { useContext, useEffect, useState } from 'react';
import {Box, Checkbox, CircularProgress, FormControlLabel, Grid, InputLabel, Typography, useTheme} from '@mui/material';
import MainButton from '../../UI/MainButton';
import isEmail from 'validator/lib/isEmail';
import SnackbarContext from '../../../store/snackbar-context';
import FormTextField from '../../UI/FormTextField';
import FormDropdown from '../../UI/FormDropdown';
import {
	getAllTeachers,
	getDistricts,
	getSchools,
	getSpecializations,
	getTeachersByDistrictId,
} from '../../../api/user-api';
import FormComboBox from '../../UI/FormComboBox';
import DividerWithButton from '../../UI/DividerWithButton';
import { loginPage } from '../../../store/fixed-routing';
import { registerNewStudent } from '../../../api/auth-api';
import TextWithDivider from '../../UI/TextWithDivider';
import school from '../../../model/School';
import dayjs, {Dayjs} from "dayjs";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {useCustomTheme} from "../../../store/theme-context";

const formErrors = {
	titleError: 'Proszę wybrać tytuł',
	nameError: 'Proszę uzupełnić imię',
	parentNameError: 'Proszę uzupełnić imię i nazwisko rodzica/opiekuna prawnego',
	parentEmailError: 'Proszę uzupełnić email rodzica/opiekuna prawnego',
	secondNameError: 'Proszę uzupełnić drugie imię',
	surnameError: 'Proszę uzupełnić nazwisko',
	classError: 'Proszę wybrać klasę',
	birthdayError: 'Wprowadź poprawną datę urodzenia',
	specializationError: 'Proszę wybrać specjalizację',
	emailError: 'Wprowadź poprawny email',
	phoneError: '',
	schoolError: 'Proszę wybrać szkołę',
	districtError: 'Proszę wybrać okręg',
	teacherError: 'Proszę wybrać nauczyciela',
	streetError: 'Proszę wpisać ulicę',
	buildingError: 'Proszę wpisać numer budynku',
	apartmentError: '',
	zipCodeError: 'Proszę wpisać poprawny kod pocztowy',
	cityError: 'Proszę uzupełnić pole z miastem',
	rodoError: 'Proszę zaznaczyć zgody',
	consentError: 'Proszę zaznaczyć zgody',
};

const parentError = {
	parentEmailError: 'Wprowadź poprawny potwierdzający adres email'
}

const RegisterStudent = () => {
	const isSmallOlympic = process.env.REACT_APP_NAME === 'OLiJPSP';
	const { palette, typography } = useTheme();
	const { isHighContrast } = useCustomTheme();
	const [districts, setDistricts] = useState<any>();
	const [schools, setSchools] = useState<any>();
	const [specializations, setSpecializations] = useState<any>();
	const [allTeachers, setAllTeachers] = useState<any>();
	const [districtTeachers, setDistrictTeachers] = useState<any>();
	const [showAllTeachers, setShowAllTeachers] = useState<boolean>(false);
	const [districtsDisabled, setDistrictsDisabled] = useState<boolean>(true);
	const [validated, setValidated] = useState(false);
	const { setMsg } = useContext(SnackbarContext);
	const [formInfo, setFormInfo] = useState({
		personalTitle: '',
		parentName: '',
		parentEmail: '',
		firstName: '',
		secondName: '',
		lastName: '',
		class: '',
		specializationId: '',
		email: '',
		phone: '',
		birthday: null as Dayjs | null,
		districtId: '',
		teachersIds: [],
		street: '',
		building: '',
		apartment: '',
		zipCode: '',
		city: '',
		schoolId: '',
		specialCertificate: false,
		isStudentUnderage: false,
	});

	const [helperInputInfo, setHelperInputInfo] = useState({
		district: '',
		teachers: '',
		specialization: '',
		school: '',
		rodo: '',
		consent: '',
	});

	const maximumNumberOfTeachers = 3;

	const [districtNames, setDistrictNames] = useState<any>([]);
	const [specializationNames, setSpecializationNames] = useState<any>([]);
	const [schoolNames, setSchoolNames] = useState<any>([]);
	const [allTeachersNames, setAllTeachersNames] = useState<any>([]);
	const [districtTeachersNames, setDistrictTeachersNames] = useState<any>([]);
	const [parentEmailConfirmation, setParentEmailConfirmation] = useState<string>('');
	const [isSecondName, setIsSecondName] = useState<string>('');
	const [parentEmailConfirmationError, setParentEmailConfirmationError] = useState<any>(parentError);

	useEffect(() => {
		const fetchData = async () => {
			const districts = await getDistricts();
			const schools = await
				getSchools();
			const specializations = await getSpecializations();
			const allTeachers = await getAllTeachers();
			setDistricts(districts);
			setSchools(schools);
			setSpecializations(specializations);
			setAllTeachers(allTeachers);

			if (districts) {
				setDistrictNames(districts.map((district: any) => district.name));
			}

			if (specializations) {
				setSpecializationNames(specializations.map((specialization: any) => specialization.name));
			}

			if (schools) {
				const schoolList = schools
					.sort((a: any, b: any) => {
						if (a.city < b.city) {
							return -1;
						}
						if (a.city > b.city) {
							return 1;
						}
						return 0;
					})
					.map((school: any) => school.city.toUpperCase() + ', ' + school.name.toUpperCase());
				setSchoolNames(Array.from(new Set(schoolList)));
			}

			if (allTeachers) {
				const teachersList = allTeachers.map(
					(teacher: any) => teacher.degree + ' ' + teacher.name + ' ' + teacher.surname,
				);
				setAllTeachersNames(Array.from(new Set(teachersList)));
			}
		};
		fetchData().catch((error) => console.log(error));
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			setDistrictTeachersNames([]);
			const districtTeachers = await getTeachersByDistrictId(`${formInfo.districtId}`);
			setDistrictTeachers(districtTeachers);

			if (districtTeachers) {
				const teachersList = districtTeachers.map(
					(teacher: any) => teacher.degree + ' ' + teacher.name + ' ' + teacher.surname,
				);
				setDistrictTeachersNames(teachersList);
			}
		};
		fetchData().catch((error) => console.log(error));
	}, [formInfo.districtId, helperInputInfo.school, helperInputInfo.district]);

	const [formValidation, setFormValidation] = useState(formErrors);

	const titleHandler = (value: string) => {
		if (value.length < 1) {
			setFormValidation({
				...formValidation,
				titleError: formErrors.titleError,
			});
		} else {
			setFormValidation({
				...formValidation,
				titleError: '',
			});
		}
		setFormInfo((prevState) => {
			return {
				...prevState,
				personalTitle: value,
			};
		});
	};

	const nameHandler = (value: string) => {
		if (value.length < 1) {
			setFormValidation({
				...formValidation,
				nameError: formErrors.nameError,
			});
		} else {
			setFormValidation({
				...formValidation,
				nameError: '',
			});
		}
		setFormInfo((prevState) => {
			return {
				...prevState,
				firstName: value,
			};
		});
	};

	const parentNameHandler = (value: string) => {
		if (value.length < 1) {
			setFormValidation({
				...formValidation,
				parentNameError: formErrors.parentNameError,
			});
		} else {
			setFormValidation({
				...formValidation,
				parentNameError: '',
			});
		}
		setFormInfo((prevState) => {
			return {
				...prevState,
				parentName: value,
			};
		});
	};

	const secondNameHandler = (value: string) => {
		if (value.length < 1) {
			setFormValidation({
				...formValidation,
				secondNameError: formErrors.secondNameError,
			});
		} else {
			setFormValidation({
				...formValidation,
				secondNameError: '',
			});
		}
		setFormInfo((prevState) => {
			return {
				...prevState,
				secondName: value,
			};
		});
	};

	const surnameHandler = (value: string) => {
		if (value.length < 1) {
			setFormValidation({
				...formValidation,
				surnameError: formErrors.surnameError,
			});
		} else {
			setFormValidation({
				...formValidation,
				surnameError: '',
			});
		}
		setFormInfo((prevState) => {
			return {
				...prevState,
				lastName: value,
			};
		});
	};

	const birthdayHandler = (value: Dayjs | null) => {
		if (!value) {
			setFormValidation({
				...formValidation,
				birthdayError: formErrors.birthdayError,
			});
		} else {
			setFormValidation({
				...formValidation,
				birthdayError: '',
			});
		}
		setFormInfo((prevState) => {
			return {
				...prevState,
				birthday: value,
			};
		});
	};

	const classHandler = (value: string) => {
		if (value.length < 1) {
			setFormValidation({
				...formValidation,
				classError: formErrors.classError,
			});
		} else {
			setFormValidation({
				...formValidation,
				classError: '',
			});
		}
		setFormInfo((prevState) => {
			return {
				...prevState,
				class: value,
			};
		});
	};

	const specializationHandler = (value: string) => {
		const specialization = specializations.find((specialization: any) => specialization.name === value);
		if (value.length < 1) {
			setFormValidation({
				...formValidation,
				specializationError: formErrors.specializationError,
			});
		} else {
			setFormValidation({
				...formValidation,
				specializationError: '',
			});
		}
		setFormInfo((prevState) => {
			return {
				...prevState,
				specializationId: specialization && specialization.id,
			};
		});
		setHelperInputInfo((prevState) => {
			return {
				...prevState,
				specialization: value,
			};
		});
	};

	const emailHandler = (value: string) => {
		if (value.length < 1) {
			setFormValidation({
				...formValidation,
				emailError: formErrors.emailError,
			});
		} else if (!isEmail(value)) {
			setFormValidation({
				...formValidation,
				emailError: 'Niepoprawny adres e-mail',
			});
		} else {
			setFormValidation({
				...formValidation,
				emailError: '',
			});
		}
		setFormInfo((prevState) => {
			return {
				...prevState,
				email: value,
			};
		});
	};

	const parentEmailHandler = (value: string) => {
		if (value.length < 1) {
			setFormValidation({
				...formValidation,
				parentEmailError: formErrors.parentEmailError,
			});
		} else if (!isEmail(value)) {
			setFormValidation({
				...formValidation,
				parentEmailError: 'Niepoprawny adres e-mail',
			});
		} else {
			setFormValidation({
				...formValidation,
				parentEmailError: '',
			});
		}
		setFormInfo((prevState) => {
			return {
				...prevState,
				parentEmail: value,
			};
		});
	};

	const parentEmailConfirmationHandler = (value: string) => {
		if (value.length < 1) {
			setParentEmailConfirmationError({
				...formValidation,
				parentEmailError: parentError.parentEmailError,
			});
		} else if (!isEmail(value)) {
			setParentEmailConfirmationError({
				...formValidation,
				parentEmailError: 'Wprowadź poprawny potwierdzający adres email',
			});
		} else if (value !== formInfo.parentEmail) {
			setParentEmailConfirmationError({
				...formValidation,
				parentEmailError: 'Wprowadź poprawny potwierdzający adres email',
			});
		} else {
			setParentEmailConfirmationError({
				...formValidation,
				parentEmailError: '',
			});
		}
		setParentEmailConfirmation(value);
	};

	const phoneHandler = (value: string) => {
		setFormInfo((prevState) => {
			return {
				...prevState,
				phone: value,
			};
		});
	};

	const schoolHandler = (e: any, value: string) => {
		setDistrictsDisabled(false);

		const school = schools.find((school: any) => {
			return school.city.toUpperCase() + ', ' + school.name.toUpperCase() === value.toUpperCase();
		});
		const district = districts.find((district: any) => school && district.id === school.districtID);
		if (value.length < 1) {
			setFormValidation({
				...formValidation,
				schoolError: formErrors.schoolError,
			});
		} else if (district && school) {
			setFormValidation({
				...formValidation,
				schoolError: '',
				districtError: '',
			});
		} else {
			setFormValidation({
				...formValidation,
				schoolError: '',
			});
		}
		setFormInfo((prevState) => {
			return {
				...prevState,
				schoolId: school && school.id,
				districtId: district && district.id,
			};
		});
		setHelperInputInfo((prevState) => {
			return {
				...prevState,
				school: value.toUpperCase(),
				district: district && district.name,
			};
		});
	};

	const districtHandler = (value: string) => {
		const district = districts.find((district: any) => district.name === value);
		if (value.length < 1) {
			setFormValidation({
				...formValidation,
				districtError: formErrors.districtError,
			});
		} else {
			setFormValidation({
				...formValidation,
				districtError: '',
			});
		}
		setFormInfo((prevState) => {
			return {
				...prevState,
				districtId: district.id,
			};
		});
		setHelperInputInfo((prevState) => {
			return {
				...prevState,
				district: value,
			};
		});
	};

	const teacherHandler = (e: any, values: any) => {
		const teachersIds: any = [];
		values.forEach((value: any) => {
			const teacher = allTeachers.find(
				(teacher: any) => teacher.degree + ' ' + teacher.name + ' ' + teacher.surname === value,
			);
			if (teacher) {
				teachersIds.push(teacher.id);
			}
		});
		if (values.length < 1) {
			setFormValidation({
				...formValidation,
				teacherError: formErrors.teacherError,
			});
		} else {
			setFormValidation({
				...formValidation,
				teacherError: '',
			});
		}
		setFormInfo((prevState) => {
			return {
				...prevState,
				teachersIds: teachersIds,
			};
		});
		setHelperInputInfo((prevState) => {
			return {
				...prevState,
				teachers: values,
			};
		});
	};

	const streetHandler = (value: string) => {
		if (value.length < 1) {
			setFormValidation({
				...formValidation,
				streetError: formErrors.streetError,
			});
		} else {
			setFormValidation({
				...formValidation,
				streetError: '',
			});
		}
		setFormInfo((prevState) => {
			return {
				...prevState,
				street: value,
			};
		});
	};

	const buildingHandler = (value: string) => {
		if (value.length < 1) {
			setFormValidation({
				...formValidation,
				buildingError: formErrors.buildingError,
			});
		} else {
			setFormValidation({
				...formValidation,
				buildingError: '',
			});
		}
		setFormInfo((prevState) => {
			return {
				...prevState,
				building: value,
			};
		});
	};

	const apartmentHandler = (value: string) => {
		if (value.length < 1) {
			setFormValidation({
				...formValidation,
				apartmentError: formErrors.apartmentError,
			});
		} else {
			setFormValidation({
				...formValidation,
				apartmentError: '',
			});
		}
		setFormInfo((prevState) => {
			return {
				...prevState,
				apartment: value,
			};
		});
	};

	const zipCodeHandler = (value: string) => {
		const numericValue = value.replace(/\D/g, '');
		const formattedValue = numericValue.replace(/(\d{2})(\d{1,})/, '$1-$2');
		formInfo.zipCode = formattedValue;

		if (formattedValue.length === 6) {
			setFormValidation({
				...formValidation,
				zipCodeError: '',
			});
		} else {
			setFormValidation({
				...formValidation,
				zipCodeError: formErrors.zipCodeError,
			});
		}
		setFormInfo((prevState) => {
			return {
				...prevState,
				zipCode: formattedValue,
			};
		});
	};

	const cityHandler = (value: string) => {
		if (value.length < 1) {
			setFormValidation({
				...formValidation,
				cityError: formErrors.cityError,
			});
		} else {
			setFormValidation({
				...formValidation,
				cityError: '',
			});
		}
		setFormInfo((prevState) => {
			return {
				...prevState,
				city: value,
			};
		});
	};

	const rodoHandler = (e: any) => {
		if (!e.target.checked) {
			setFormValidation({
				...formValidation,
				rodoError: formErrors.rodoError,
			});
		} else {
			setFormValidation({
				...formValidation,
				rodoError: '',
			});
		}
		setHelperInputInfo((prevState) => {
			return {
				...prevState,
				rodo: e.target.checked,
			};
		});
	};

	const consentHandler = (e: any) => {
		if (!e.target.checked) {
			setFormValidation({
				...formValidation,
				consentError: formErrors.consentError,
			});
		} else {
			setFormValidation({
				...formValidation,
				consentError: '',
			});
		}
		setHelperInputInfo((prevState) => {
			return {
				...prevState,
				consent: e.target.checked,
			};
		});
	};

	const specialCertificateHandler = (e: any) => {
		setFormInfo((prevState) => {
			return {
				...prevState,
				specialCertificate: e.target.checked,
			};
		});
	};

	const adultStudentHandler = (e: any) => {
		setFormInfo((prevState) => {
			return {
				...prevState,
				isStudentUnderage: e.target.checked,
			};
		});
	};

	const resetInputs = () => {
		setFormInfo((prevState) => {
			return {
				...prevState,
				personalTitle: '',
				parentName: '',
				parentEmail: '',
				firstName: '',
				secondName: '',
				lastName: '',
				class: '',
				specializationId: '',
				email: '',
				phone: '',
				birthday: null as Dayjs | null,
				districtId: '',
				teachersIds: [],
				street: '',
				building: '',
				apartment: '',
				zipCode: '',
				city: '',
				schoolId: '',
				specialCertificate: false,
				isStudentUnderage: false,
			};
		});
		setHelperInputInfo((prevState) => {
			return {
				...prevState,
				school: '',
				district: '',
				specialization: '',
				teachers: '',
				rodo: '',
				consent: '',
			};
		});
		setValidated(false);
	};

	const [loading, setLoading] = useState(false);

	const actionSend = () => {
		setValidated(true);

		if (formValidation.titleError !== '') {
			setMsg({ msg: formValidation.titleError, severity: 'warning' });
			return;
		}
		if (formValidation.nameError !== '') {
			setMsg({ msg: formValidation.nameError, severity: 'warning' });
			return;
		}
		if (formInfo.isStudentUnderage && formValidation.parentNameError !== '') {
			setMsg({ msg: formValidation.parentNameError, severity: 'warning' });
			return;
		}
		if (formInfo.isStudentUnderage && formValidation.parentEmailError !== '') {
			setMsg({ msg: formValidation.parentEmailError, severity: 'warning' });
			return;
		}
		if (isSecondName && formValidation.secondNameError !== '') {
			setMsg({ msg: formValidation.secondNameError, severity: 'warning' });
			return;
		}
		if (formValidation.surnameError !== '') {
			setMsg({ msg: formValidation.surnameError, severity: 'warning' });
			return;
		}
		if (formValidation.birthdayError !== '') {
			setMsg({ msg: formValidation.birthdayError, severity: 'warning' });
			return;
		}
		if (formValidation.streetError !== '') {
			setMsg({ msg: formValidation.streetError, severity: 'warning' });
			return;
		}
		if (formValidation.buildingError !== '') {
			setMsg({ msg: formValidation.buildingError, severity: 'warning' });
			return;
		}
		if (formValidation.apartmentError !== '') {
			setMsg({ msg: formValidation.apartmentError, severity: 'warning' });
			return;
		}
		if (formValidation.zipCodeError !== '') {
			setMsg({ msg: formValidation.zipCodeError, severity: 'warning' });
			return;
		}
		if (formValidation.cityError !== '') {
			setMsg({ msg: formValidation.cityError, severity: 'warning' });
			return;
		}
		if (formValidation.emailError !== '') {
			setMsg({ msg: formValidation.emailError, severity: 'warning' });
			return;
		}
		if (formValidation.phoneError !== '') {
			setMsg({ msg: formValidation.phoneError, severity: 'warning' });
			return;
		}
		if (formValidation.classError !== '') {
			setMsg({ msg: formValidation.classError, severity: 'warning' });
			return;
		}
		if (formValidation.specializationError !== '') {
			setMsg({ msg: formValidation.specializationError, severity: 'warning' });
			return;
		}
		if (formValidation.schoolError !== '') {
			setMsg({ msg: formValidation.schoolError, severity: 'warning' });
			return;
		}
		if (formValidation.districtError !== '') {
			setMsg({ msg: formValidation.districtError, severity: 'warning' });
			return;
		}
		if (formValidation.teacherError !== '') {
			setMsg({ msg: formValidation.teacherError, severity: 'warning' });
			return;
		}
		if (formValidation.rodoError !== '') {
			setMsg({ msg: formValidation.rodoError, severity: 'warning' });
			return;
		}
		if (formValidation.consentError !== '') {
			setMsg({ msg: formValidation.consentError, severity: 'warning' });
			return;
		}
		if (formInfo.isStudentUnderage && parentEmailConfirmationError.parentEmailError !== '') {
			setMsg({ msg: parentEmailConfirmationError.parentEmailError, severity: 'warning' });
			return;
		}
		if (formInfo) {
			setLoading(true);
			registerNewStudent(formInfo, (error) => error)
				.then((res: any) => {
					setLoading(false);
					if (typeof res === 'string' && res === '') {
						setMsg({
							msg: 'Pomyślnie zarejestrowano',
							severity: 'success',
						});
						resetInputs();
					} else if (typeof res === 'string') {
						setMsg({
							msg: res,
							severity: 'error',
						});
					}
				})
				.catch((error: any) => {
					setLoading(false);
					setMsg({
						msg: 'Nastąpił błąd przy rejestracji! Spróbuj ponownie później.',
						severity: 'error',
					});
				});
			setLoading(false);
		}
	};

	return (
		<Grid
			width={'100%'}
			maxWidth={'650px'}
			display={'flex'}
			flexDirection={'column'}
			gap={'24px'}
		>
			<Typography
				variant={'h3'}
				fontWeight={'500'}
				color={palette.primary.main}
			>
				Zarejestruj się - uczeń
			</Typography>
			<Grid
				component={'form'}
				display={'flex'}
				flexDirection={'column'}
			>
				<TextWithDivider
					text={formInfo.isStudentUnderage ? 'Informacje o rodzicu/opiekunie' : 'Informacje o Tobie'}
					sx={{
						mt: '16px',
						mb: '24px',
					}}
				/>
				<Box
					display={'flex'}
					flexDirection={'column'}
					alignItems={'flex-start'}
					gap={'16px'}
					width={'100%'}
					mb={'16px'}
				>
					<Box
						sx={{
							'& span': {
								fontSize: typography.subtitle2.fontSize,
								fontWeight: '500',
								color: palette.primary.main,
							},
						}}
					>
						<FormControlLabel
							control={
								<Checkbox
									sx={{
										borderRadius: 3,
										width: 16,
										height: 16,
										mr: '6px',
									}}
								/>
							}
							label='Uczestnik niepełnoletni'
							onChange={adultStudentHandler}
							sx={{
								ml: '2px',
							}}
						/>
					</Box>
					{formInfo.isStudentUnderage && <>
					    <FormTextField
							id={'parentName'}
							type={'text'}
							label={'Imię i nazwisko rodzica/opiekuna prawnego'}
							validated={validated}
							errorMsg={formValidation.parentNameError}
							value={formInfo.parentName}
							valueChangeHandler={parentNameHandler}
					    />
						<FormTextField
							id={'parentEmail'}
							type={'email'}
							label={'Adres e-mail rodzica/opiekuna prawnego, aby wysłać zaświadczenie finalisty/laureata'}
							validated={validated}
							errorMsg={formValidation.parentEmailError}
							value={formInfo.parentEmail}
							valueChangeHandler={parentEmailHandler}
						/>
					    <FormTextField
	                        id={'parentEmailConfirmation'}
	                        type={'email'}
	                        label={'Aby uniknąć błędów w korespondencji prosimy o powtórzenie adresu e-mail'}
	                        validated={validated}
	                        errorMsg={parentEmailConfirmationError.parentEmailError}
	                        value={parentEmailConfirmation}
	                        valueChangeHandler={parentEmailConfirmationHandler}
					    />
					</>}
					{formInfo.isStudentUnderage && <TextWithDivider
						text={'Informacje o uczestniku'}
						sx={{
							mt: '16px',
							mb: '24px',
						}}
					/>}
					<FormDropdown
						id={'title'}
						label={'Tytuł (Pan/Pani)'}
						validated={validated}
						errorMsg={formValidation.titleError}
						value={formInfo.personalTitle}
						values={['Pan', 'Pani']}
						valueChangeHandler={titleHandler}
					/>
					<FormTextField
						id={'name'}
						type={'text'}
						label={'Imię'}
						validated={validated}
						errorMsg={formValidation.nameError}
						value={formInfo.firstName}
						valueChangeHandler={nameHandler}
					/>
					<Box
						sx={{
							'& span': {
								fontSize: typography.subtitle2.fontSize,
								fontWeight: '500',
								color: palette.primary.main,
							},
						}}
					>
						<FormControlLabel
							control={
								<Checkbox
									sx={{
										borderRadius: 3,
										width: 16,
										height: 16,
										mr: '6px',
									}}
								/>
							}
							label='Czy uczestnik posiada drugie imię?'
							onChange={(e: any) => setIsSecondName(e.target.checked)}
							sx={{
								ml: '2px',
							}}
						/>
					</Box>
					{isSecondName && <FormTextField
						id={'secondName'}
						type={'text'}
						label={'Drugie imię'}
						validated={validated}
						errorMsg={formValidation.secondNameError}
						value={formInfo.secondName}
						valueChangeHandler={secondNameHandler}
					/>}
					<FormTextField
						id={'surname'}
						type={'text'}
						label={'Nazwisko'}
						validated={validated}
						errorMsg={formValidation.surnameError}
						value={formInfo.lastName}
						valueChangeHandler={surnameHandler}
					/>
					<Box sx={{width: '100%'}}>
						<LocalizationProvider
							dateAdapter={AdapterDayjs}
							adapterLocale='pl'
						>
							<InputLabel
								sx={{
									fontSize: typography.subtitle2.fontSize,
									fontWeight: '500',
									color: palette.primary.main,
									mb: '4px'
								}}
							>
								Data urodzenia
							</InputLabel>
							<DatePicker
								label=''
								value={formInfo.birthday}
								maxDate={dayjs()}
								minDate={dayjs('2000-01-01')}
								slotProps={{textField: {size: 'small', error: validated && !formInfo.birthday && !!formErrors.birthdayError}}}
								onChange={birthdayHandler}
								sx={{
									width: '100%',
									borderRadius: '4px',
									borderColor: palette.primary.main,
									'& div, & input, & fieldset': {
										borderRadius: '4px',
										borderColor: palette.primary.main,
										color: palette.primary.main,
									},
									'&:hover fieldset': {
										borderColor: isHighContrast ? palette.primary.main + '!important' : palette.primary.main,
									},
									'& svg': {
										color: isHighContrast ? palette.primary.main + '!important' : palette.primary.main
									}
								}}
							/>
						</LocalizationProvider>
					</Box>
					<Box
						display={'flex'}
						flexDirection={'column'}
						width={'100%'}
						gap={'16px'}
					>
						<FormTextField
							id={'street'}
							type={'text'}
							label={'Adres zamieszkania/korespondencyjny'}
							placeholder='Ulica'
							validated={validated}
							errorMsg={formValidation.streetError}
							value={formInfo.street}
							valueChangeHandler={streetHandler}
						/>
						<FormTextField
							id={'building'}
							type={'text'}
							label={''}
							placeholder='Numer budynku'
							validated={validated}
							errorMsg={formValidation.buildingError}
							value={formInfo.building}
							valueChangeHandler={buildingHandler}
						/>
						<FormTextField
							id={'apartment'}
							type={'text'}
							label={''}
							placeholder='Numer mieszkania'
							validated={validated}
							errorMsg={formValidation.apartmentError}
							value={formInfo.apartment}
							valueChangeHandler={apartmentHandler}
						/>
						<Box
							display={'flex'}
							gap={'16px'}
							justifyContent={'space-between'}
						>
							<FormTextField
								id={'zip'}
								type={'text'}
								label={''}
								placeholder='__-___'
								validated={validated}
								errorMsg={formValidation.zipCodeError}
								value={formInfo.zipCode}
								valueChangeHandler={zipCodeHandler}
							/>
							<FormTextField
								id={'city'}
								type={'text'}
								label={''}
								placeholder='Miasto'
								validated={validated}
								errorMsg={formValidation.cityError}
								value={formInfo.city}
								valueChangeHandler={cityHandler}
							/>
						</Box>
					</Box>
					<FormTextField
						id={'email'}
						type={'email'}
						label={'E-mail'}
						validated={validated}
						errorMsg={formValidation.emailError}
						value={formInfo.email}
						valueChangeHandler={emailHandler}
					/>
					<FormTextField
						id={'phone'}
						type={'tel'}
						label={'Numer telefonu (opcjonalnie)'}
						validated={validated}
						errorMsg={formValidation.phoneError}
						value={formInfo.phone}
						valueChangeHandler={phoneHandler}
					/>
					<TextWithDivider
						text={'Informacje o szkole'}
						dividerColor={palette.secondary.main}
						sx={{
							mt: '32px',
							mb: '8px',
						}}
					/>
					<FormDropdown
						id={'class'}
						label={'Klasa'}
						validated={validated}
						errorMsg={formValidation.classError}
						value={formInfo.class}
						values={!isSmallOlympic ? [1, 2, 3, 4, 5] : [4, 5, 6, 7, 8]}
						valueChangeHandler={classHandler}
					/>
					<FormDropdown
						id={'specialization'}
						label={'Specjalizacja'}
						validated={validated}
						errorMsg={formValidation.specializationError}
						value={helperInputInfo.specialization}
						values={specializationNames}
						valueChangeHandler={specializationHandler}
					/>
					<FormComboBox
						id={'school'}
						label={'Szkoła'}
						validated={validated}
						errorMsg={formValidation.schoolError}
						value={helperInputInfo.school}
						values={schoolNames && schoolNames}
						valueChangeHandler={schoolHandler}
					/>
					<FormDropdown
						id={'district'}
						label={'Okręg'}
						validated={validated}
						errorMsg={formValidation.districtError}
						value={helperInputInfo.district}
						values={districtNames && districtNames}
						valueChangeHandler={districtHandler}
						isDisabled={districtsDisabled}
						disabledLabel='Nazwa okręgu - pojawia się automatycznie po wyborze szkoły'
					/>
					<Box
						display={'flex'}
						flexDirection={'column'}
						width={'100%'}
						sx={{
							'& span': {
								fontSize: typography.subtitle2.fontSize,
								fontWeight: '500',
								color: palette.primary.main,
							},
						}}
					>
						<FormComboBox
							id={'teacher'}
							label={'Nauczyciel'}
							multiple={true}
							multipleMax={maximumNumberOfTeachers}
							validated={validated}
							errorMsg={formValidation.teacherError}
							value={helperInputInfo.teachers}
							values={showAllTeachers ? allTeachersNames : districtTeachersNames}
							valueChangeHandler={teacherHandler}
						/>
						<FormControlLabel
							control={
								<Checkbox
									sx={{
										borderRadius: 3,
										width: 16,
										height: 16,
										mr: '6px',
									}}
								/>
							}
							label='Pokaż nauczycieli spoza mojego okręgu'
							onChange={(e: any) => setShowAllTeachers(e.target.checked)}
							sx={{
								ml: '2px',
							}}
						/>
					</Box>
					<TextWithDivider
						text={'Dodatkowe'}
						dividerColor={palette.secondary.light}
						sx={{
							mt: '32px',
						}}
					/>
					<Box
						sx={{
							'& span': {
								fontSize: typography.subtitle2.fontSize,
								fontWeight: '500',
								color: palette.primary.main,
							},
						}}
					>
						<FormControlLabel
							control={
								<Checkbox
									sx={{
										borderRadius: 3,
										width: 16,
										height: 16,
										mr: '6px',
									}}
								/>
							}
							label='Posiadam orzeczenie lub opinie o dysgrafii'
							onChange={specialCertificateHandler}
							sx={{
								ml: '2px',
							}}
						/>
					</Box>
					<TextWithDivider
						text={'Zgody musi wypełnić osoba pełnoletnia'}
						dividerColor={palette.secondary.light}
						sx={{
							mt: '32px',
						}}
					/>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: '8px',
							'& span': {
								fontSize: typography.subtitle2.fontSize,
								fontWeight: '500',
								color: palette.primary.main,
							},
						}}
					>
						<FormControlLabel
							control={
								<Checkbox
									sx={{
										borderRadius: 3,
										width: 16,
										height: 16,
										mr: '6px',
									}}
								/>
							}
							label='Zgoda RODO'
							onChange={rodoHandler}
							sx={{
								ml: '2px',
							}}
						/>
						<FormControlLabel
							control={
								<Checkbox
									sx={{
										borderRadius: 3,
										width: 16,
										height: 16,
										mr: '6px',
									}}
								/>
							}
							label='Oświadczam, że powyższe dane są zgodne ze stanem faktycznym'
							onChange={consentHandler}
							sx={{
								ml: '2px',
							}}
						/>
					</Box>
				</Box>
				<Box
					mt={'16px'}
					mb={'56px'}
					alignSelf={'center'}
					display={'flex'}
					justifyContent={'center'}
					alignItems={'center'}
					position={'relative'}
				>
					<Box alignSelf={'center'}>
						<MainButton
							title={'Zarejestruj się'}
							darkVersion
							onClick={actionSend}
							sx={{
								alignSelf: 'center',
								lineHeight: '20px',
								letterSpacing: '0.18px',
							}}
						/>
					</Box>
					<CircularProgress
						sx={{
							display: loading ? 'block' : 'none',
							position: 'absolute',
							right: '-60px',
						}}
					/>
				</Box>
			</Grid>
			<Box mt={'64px'}>
				<DividerWithButton
					label={'Masz już konto?'}
					hrefPath={loginPage}
					buttonTitle={'Zaloguj się'}
				/>
			</Box>
		</Grid>
	);
};

export default RegisterStudent;
