import { Box, Divider, Grid, styled, Typography, useTheme } from '@mui/material';
import MainButton from '../../components/UI/MainButton';

const StyledDivider = styled(Divider)(({ theme }) => ({
	zIndex: -1,
	bgcolor: theme.palette.primary.main,
	borderColor: theme.palette.primary.main,
	borderLeftWidth: '4px',
	height: '50px',
	margin: '8px 38px -8px 38px',
	[theme.breakpoints.down('sm')]: {
		display: 'none',
	},
}));

type Props = {
	content: any;
	centered?: boolean;
	links: any[];
};
const LeftPane = ({ content, centered, links }: Props) => {
	const { palette, breakpoints } = useTheme();

	return (
		<Grid
			item
			xs={12}
			lg={6}
			pl={centered ? 0 : { xs: '40px', md: '80px', lg: '8%' }}
			display={'flex'}
			flexDirection={'column'}
			justifyContent={'center'}
			position={'relative'}
			mt={'90px'}
			pb={'40px'}
			pt={'40px'}
			sx={{
				overflowY: 'scroll',
				'&::-webkit-scrollbar': {
					display: 'none',
				},
				[breakpoints.down('lg')]: {
					my: '26px',
				},
				[breakpoints.down('sm')]: {
					gap: '70px',
				},
			}}
		>
			{content &&
				content.length > 0 &&
				content.map((c: any, i: number) => (
					<Grid
						key={'schedule-item-' + i}
						item
						container
						flexDirection={'column'}
						alignItems={centered ? 'center' : 'start'}
					>
						{content.length !== 1 && (
							<StyledDivider
								variant='fullWidth'
								orientation='vertical'
								sx={{
									height: '100px',
								}}
							/>
						)}
						<Box
							key={'schedule' + i}
							display={'flex'}
							gap={'8px'}
							alignItems={centered ? 'center' : 'start'}
							flexDirection={centered ? 'column' : 'row'}
							justifyContent={'center'}
							sx={{
								'& *': {
									textAlign: 'center',
								},
							}}
						>
							<Box
								bgcolor={palette.background.default}
								border={'4px solid'}
								borderColor={palette.primary.main}
								borderRadius={'100%'}
								display={'flex'}
								justifyContent={'center'}
								alignItems={'center'}
								sx={{
									position: 'relative',
									aspectRatio: '1/1',
									width: '80px',
									height: '80px',
									[breakpoints.down('sm')]: {
										width: '50px',
										height: '50px',
									},
								}}
							>
								{!centered && content.length > 1 && (
									<StyledDivider
										variant='fullWidth'
										orientation='vertical'
										sx={{
											height: '200px',
											position: 'absolute',
											top: 0,
											left: '-4px',
										}}
									/>
								)}
								<Typography
									variant='h3'
									sx={{
										pl: '6px',
										fontWeight: '500 !important',
										color: palette.primary.main,
										letterSpacing: '0.32px',
									}}
								>
									{i + 1 + '.'}
								</Typography>
							</Box>
							<Box
								display={'flex'}
								flexDirection={'column'}
								alignItems={centered ? 'center' : 'start'}
								gap={'8px'}
							>
								{c.first_line && <Typography
									variant='h5'
									fontWeight={'500 !important'}
									color={palette.primary.main}
									sx={{ textAlign: centered ? 'center' : 'left' }}
								>
									{c.first_line}
								</Typography>}
								{c.second_line && <Typography
									variant='h5'
									fontWeight={'400 !important'}
									color={palette.primary.main}
									sx={{ textAlign: centered ? 'center' : 'left' }}
								>
									{c.second_line}
								</Typography>}
								{c.third_line && <Typography
									variant='h5'
									fontWeight={'400 !important'}
									color={palette.primary.main}
									sx={{ textAlign: centered ? 'center' : 'left' }}
								>
									{c.third_line}
								</Typography>}
								{c.fourth_line && <Typography
									variant='h5'
									fontWeight={'400 !important'}
									color={palette.primary.main}
									sx={{ textAlign: centered ? 'center' : 'left' }}
								>
									{c.fourth_line}
								</Typography>}
							</Box>
						</Box>
						{content.length !== 1 && (
							<StyledDivider
								variant='fullWidth'
								orientation='vertical'
							/>
						)}
					</Grid>
				))}
			<Box
				mt={'72px'}
				display={'flex'}
				flexDirection={'column'}
				alignItems={centered ? 'center' : 'start'}
				gap={'24px'}
			>
				{links &&
					links.length > 0 &&
					links.map((item: any, index: number) => {
						return (
							<MainButton
								key={'link' + index}
								link={item.link}
							/>
						);
					})}
			</Box>
		</Grid>
	);
};

export default LeftPane;
