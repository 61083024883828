import { Grid, InputAdornment, InputLabel, TextField, useTheme } from '@mui/material';

type Props = {
	id?: string;
	type?: string;
	label: string;
	dense?: boolean;
	validated?: boolean;
	errorMsg?: string;
	value: string | undefined;
	endIconElement?: any;
	multiline?: boolean;
	placeholder?: string;
	valueChangeHandler?: (value: string) => void;
	labelSx?: any;
	maxLength?: number;
	disabled?: boolean;
};
const FormTextField = ({
	id,
	type,
	label,
	dense,
	validated,
	errorMsg,
	valueChangeHandler,
	value,
	endIconElement,
	multiline,
	placeholder,
	labelSx,
	maxLength,
	disabled,
}: Props) => {
	const { typography, palette } = useTheme();

	return (
		<Grid
			container
			width={'100%'}
			gap={'4px'}
		>
			<InputLabel
				htmlFor={id}
				sx={{
					fontSize: dense ? '14px' : typography.subtitle2.fontSize,
					fontWeight: '500',
					color: palette.primary.main,
					...labelSx,
				}}
			>
				{label}
			</InputLabel>
			<TextField
				id={id}
				type={type}
				size='small'
				required
				disabled={disabled}
				multiline={multiline}
				placeholder={placeholder}
				sx={{
					'& input': {
						height: dense ? '19px' : '25px',
						fontSize: dense ? '14px' : '20px',
					},
					'& input, & textarea': {
						height: '28px',
						fontSize: '20px',
						color: palette.primary.main,
					},
					'& textarea': {
						lineHeight: '30px',
					},
					'& .MuiOutlinedInput-root': {
						'& fieldset': {
							borderRadius: '4px',
							borderColor: palette.primary.main,
						},
					},
					'& .MuiOutlinedInput-root:hover': {
						'& fieldset': {
							borderRadius: '4px',
							borderColor: palette.primary.main,
						},
					}
				}}
				InputProps={{
					endAdornment: <InputAdornment position='end'>{endIconElement}</InputAdornment>,
				}}
				inputProps={{ maxLength: maxLength }}
				fullWidth
				value={value}
				error={validated && errorMsg !== ''}
				onChange={(e: any) => valueChangeHandler && valueChangeHandler(e.target.value)}
			/>
		</Grid>
	);
};

export default FormTextField;
