import { Grid, IconButton, TableCell, TableContainer, TablePagination, Typography, useTheme } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import SortIcon from '@mui/icons-material/Sort';

import {useEffect, useState} from 'react';
import { grey } from '@mui/material/colors';
import {useCustomTheme} from "../../store/theme-context";

type TableData = {
	columns: {
		header: string;
		accessor: string;
		width?: string;
		align?: string;
	}[];
	rows: any[];
};

type Props = {
	data: TableData;
};
const CustomTable = ({ data }: Props) => {
	const { theme, isHighContrast } = useCustomTheme();
	const [displayedRows, setDisplayedRows] = useState<any[]>([]);
	const [page, setPage] = useState(0);
	const [perPage, setPerPage] = useState(20);
	const [sortBy, setSortBy] = useState<{ accessor: string; desc: boolean }>({
		accessor: data.columns[0].accessor,
		desc: true,
	});

	useEffect(() => {
		const sortedRows = [
			...data.rows.sort((a: any, b: any) => {
				if (a[sortBy.accessor].props.checked !== undefined || b[sortBy.accessor].props.checked !== undefined) {
					const aIsActive = a[sortBy.accessor].props.checked;
					const bIsActive = b[sortBy.accessor].props.checked;
					let result = 0;
					if (aIsActive && !bIsActive) {
						result = -1;
					} else if (!aIsActive && bIsActive) {
						result = 1;
					}
					return (sortBy.desc ? 1 : -1) * result;
				} else if (typeof a[sortBy.accessor].props.children === 'number') {
					return (sortBy.desc ? 1 : -1) * (a[sortBy.accessor].props.children - b[sortBy.accessor].props.children);
				} else if (typeof a[sortBy.accessor].props.children === 'string') {
					return (
						(sortBy.desc ? 1 : -1) * a[sortBy.accessor].props.children.localeCompare(b[sortBy.accessor].props.children)
					);
				} else {
					return 0;
				}
			}),
		];
		const startIndex = page * perPage;
		const endIndex = startIndex + perPage;

		setDisplayedRows(sortedRows.slice(startIndex, endIndex));
	}, [sortBy, page, perPage, data]);

	const createHeader = (columns: any[]) => {
		return (
			<TableHead>
				<TableRow
					sx={{
						'> *:not(:last-child)': {
							borderRight: '1px solid #dddddd',
						},
						borderBottom: `2px solid ${theme.palette.primary.main}`,
					}}
				>
					{columns
						.filter((c) => !c.hidden)
						.map((c) => (
							<TableCell
								key={c.header}
								align={c.align}
								sx={{
									width: c.width,
									backgroundColor: c.editable ? c.bgColor : grey[100],
									color: isHighContrast ? theme.palette.primary.light : theme.palette.primary.main
								}}
							>
								<Grid
									item
									container
									flexWrap={'nowrap'}
									gap='10px'
								>
									<Typography
										sx={{
											fontSize: '16px !important',
											fontWeight: '600',
											color: isHighContrast ? theme.palette.primary.light : theme.palette.primary.main,
											textAlign: c.align ? c.align : 'center',
											whiteSpace: 'nowrap',
											width: '100%',
										}}
									>
										{c.header}
									</Typography>
									{!c.nosort && (
										<IconButton
											onClick={() => setSortBy({ accessor: c.accessor, desc: !sortBy.desc })}
											sx={{
												padding: 0,
												ml: 'auto',
											}}
										>
											{sortBy.accessor === c.accessor ? (
												sortBy.desc ? (
													<ArrowDropDownIcon fontSize={'small'} />
												) : (
													<ArrowDropUpIcon fontSize={'small'} />
												)
											) : (
												<SortIcon fontSize={'small'} />
											)}
										</IconButton>
									)}
								</Grid>
							</TableCell>
						))}
				</TableRow>
			</TableHead>
		);
	};

	const printRows = (columns: any[], rows: any[]) => {

		return rows.map((r: any, i: number) => (
			<TableRow
				key={'row-' + i}
				sx={{
					'> *:not(:last-child)': {
						borderRight: '1px solid #dddddd',
					},
				}}
			>
				{columns
					.filter((c) => !c.hidden)
					.map((c: any, i: number) => (
						<TableCell
							key={'row-' + i + c.accessor}
							align={c.align}
							valign={'bottom'}
							sx={{
								backgroundColor: c.editable ? c.bgColor : grey[100],
							}}
						>
							{r[c.accessor]}
						</TableCell>
					))}
			</TableRow>
		));
	};

	return (
		<>
			<TableContainer
				component={Paper}
				sx={{
					mt: '40px',
				}}
			>
				<Table>
					{createHeader(data.columns)}
					<TableBody>{printRows(data.columns, displayedRows)}</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[20, 50, 100]}
				component='div'
				count={data.rows.length}
				rowsPerPage={perPage}
				page={page}
				onPageChange={(event, page) => setPage(page)}
				labelRowsPerPage={'Wierszy na stronie'}
				labelDisplayedRows={({ from, to, count }) => `${from}-${to}  Łącznie: ${count}`}
				onRowsPerPageChange={(event) => {
					setPage(0);
					setPerPage(Number(event.target.value));
				}}
				sx={{
					color: theme.palette.primary.light,
					backgroundColor: theme.palette.primary.main
				}}
			/>
		</>
	);
};

export default CustomTable;
