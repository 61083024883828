import { createContext, ReactNode, useContext, useReducer } from 'react';
import SnackbarContext from './snackbar-context';
import { sendLoginRequest } from '../api/auth-api';
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { accountPage, panelPage } from './fixed-routing';

type AuthContextType = {
	token: string | null;
	email: string | null;
	id: number | null;
	firstName: string | null;
	lastName: string | null;
	role: string | null;
	districtId: number | null;
	isAdmin: boolean;
	isTeacher: boolean;
	isSecretary: boolean;
	isStudent: boolean;
	login: (login: string, password: string) => void;
	logout: () => void;
	isLoggedIn: () => boolean;
};

type ActionType = {
	type: string;
	value?: any;
};

const LOGIN = 'setLogin';
const LOGOUT = 'setLogout';
const USER = 'userData';

const INITIAL_AUTH_STATE: AuthContextType = {
	token: null,
	email: null,
	id: null,
	firstName: null,
	lastName: null,
	role: null,
	districtId: null,
	isAdmin: false,
	isTeacher: false,
	isSecretary: false,
	isStudent: false,
	login: (login: string, password: string) => {},
	logout: () => {},
	isLoggedIn: () => false,
};

const authReducer = (state: AuthContextType, action: ActionType): AuthContextType => {
	switch (action.type) {
		case LOGIN: {
			return {
				...state,
				token: action.value.token,
				email: action.value.email,
				id: action.value.id,
				firstName: action.value.firstName,
				lastName: action.value.lastName,
				role: action.value.role,
				districtId: action.value.districtId,
				isAdmin: action.value.role === 'administrator',
				isSecretary: action.value.role === 'administrator' || action.value.role === 'secretary',
				isTeacher: action.value.role === 'teacher',
				isStudent: action.value.role === 'student',
			};
		}
		case LOGOUT: {
			return {
				...state,
				token: null,
				email: null,
				id: null,
				firstName: null,
				lastName: null,
				role: null,
				districtId: null,
				isAdmin: false,
				isSecretary: false,
				isTeacher: false,
				isStudent: false,
			};
		}
		default:
			return state;
	}
};

const getInitialState = () => {
	const userJson = localStorage.getItem(USER);
	if (userJson) {
		const user = JSON.parse(userJson);
		try {
			const tokenData = jwt_decode(user.token) as any;

			return {
				...INITIAL_AUTH_STATE,
				token: user.token,
				email: user.email,
				id: user.id,
				firstName: user.firstName,
				lastName: user.lastName,
				role: user.role,
				districtId: tokenData.districtId,
				isAdmin: user.role === 'administrator',
				isSecretary: user.role === 'administrator' || user.role === 'secretary',
				isTeacher: user.role === 'teacher',
				isStudent: user.role === 'student',
			};
		} catch (error) {
			console.log(error);
		}
	}
	return INITIAL_AUTH_STATE;
};

export const AuthContextProvider = ({ children }: { children: ReactNode }) => {
	const [authState, dispatch] = useReducer(authReducer, getInitialState());
	const { setMsg } = useContext(SnackbarContext);
	const navigate = useNavigate();

	const isLoggedIn = () => {
		if (authState.token) {
			try {
				const userData = jwt_decode(authState.token) as any;
				if (userData.exp > Math.round(Date.now() / 1000)) {
					return true;
				}
			} catch (error) {
				console.log(error);
			}
		}
		if (typeof window !== 'undefined') {
			localStorage.removeItem(USER);
			dispatch({ type: LOGOUT });
		}
		return false;
	};

	const login = (email: string, password: string) => {
		sendLoginRequest(email, password, (error: any) =>
			setMsg({
				msg: 'Niepoprawny login lub hasło',
				severity: 'error',
			}),
		)
			.then((data: any) => {
				if (data) {
					const tokenData = jwt_decode(data.token) as any;
					const user = {
						token: data.token,
						firstName: data.firstName,
						lastName: data.lastName,
						email: data.email,
						id: data.id,
						role: tokenData.role,
					};
					localStorage.setItem(USER, JSON.stringify(user));
					dispatch({ type: LOGIN, value: { ...data, role: tokenData.role, districtId: tokenData.districtId } });
					if (tokenData.role === 'administrator' || tokenData.role === 'teacher' || tokenData.role === 'secretary') {
						navigate(panelPage);
					}
					if (tokenData.role === 'student') {
						navigate(accountPage);
					}
				}
			})
			.catch((error: any) => {
				setMsg({
					msg: 'Wystąpił błąd podczas logowania. Spróbuj ponownie później! ',
				});
				console.log(error);
			});
	};

	const logout = () => {
		localStorage.removeItem(USER);
		dispatch({ type: LOGOUT });
	};

	const contextValue: AuthContextType = {
		...authState,
		login,
		logout,
		isLoggedIn,
	};
	return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

const AuthContext = createContext<AuthContextType>({
	...INITIAL_AUTH_STATE,
});

export default AuthContext;
