import { FormControl, Grid, InputLabel, MenuItem, Select, useTheme } from '@mui/material';
import {useCustomTheme} from "../../store/theme-context";

type Props = {
	id: string;
	label: string;
	validated: boolean;
	errorMsg: string;
	value: string;
	values: any;
	valueChangeHandler: (value: string) => void;
	labelSx?: any;
	isDisabled?: boolean;
	disabledLabel?: string;
};
const FormDropdown = ({
	id,
	label,
	validated,
	errorMsg,
	valueChangeHandler,
	value,
	values,
	labelSx,
	isDisabled,
	disabledLabel,
}: Props) => {
	const { isHighContrast } = useCustomTheme();
	const { typography, palette } = useTheme();

	return (
		<Grid
			container
			width={'100%'}
			gap={'4px'}
			sx={{
				maxWidth: '100%',
				overflow: 'eclipse',
			}}
		>
			<InputLabel
				htmlFor={id}
				sx={{
					fontSize: typography.subtitle2.fontSize,
					fontWeight: '500',
					color: palette.primary.main,
					...labelSx,
				}}
			>
				{label}
			</InputLabel>
			<FormControl
				fullWidth
				size='small'
				error={validated && errorMsg !== ''}
				sx={{
					position: 'relative',
					height: '50px',
					'& *': {
						color: palette.primary.main,
					},
					'&:hover fieldset': {
						borderColor: isHighContrast ? palette.primary.main + '!important' : palette.primary.main,
					},
					'& div': {
						py: '0',
						my: '0',
					},
					'& div:first-of-type': {
						fontSize: '20px',
						display: 'flex',
						alignItems: 'center',
						height: '95%',
					},
					'& fieldset': {
						height: '50px',
					},
					'& input': {
						height: '0',
					},
					'& .MuiOutlinedInput-root': {
						'& fieldset': {
							borderRadius: '4px',
							borderColor: !isDisabled ? palette.primary.main : palette.customColors.gray,
							bgColor: isDisabled ? palette.customColors.gray : 'inherit',
						},
					},
				}}
			>
				<InputLabel
					htmlFor={id}
					sx={{
						display: isDisabled ? 'block' : 'none',
						fontSize: typography.subtitle2.fontSize,
						fontWeight: '500',
						color: palette.primary.main,
					}}
				>
					{disabledLabel}
				</InputLabel>
				<Select
					labelId={id}
					id={id}
					value={value}
					disabled={isDisabled}
					onChange={(e: any) => valueChangeHandler(e.target.value)}
				>
					{values &&
						values.map((value: any, index: number) => {
							return (
								<MenuItem
									value={`${value}`}
									key={index}
								>
									{value}
								</MenuItem>
							);
						})}
				</Select>
			</FormControl>
		</Grid>
	);
};

export default FormDropdown;
