import {
	Autocomplete, Box,
	Button, Checkbox,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle, FormControlLabel,
	Grid, InputLabel, Typography,
	useTheme,
} from '@mui/material';
import {updateStudent} from '../../api/content-api';
import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../../store/auth-context';
import SnackbarContext from '../../store/snackbar-context';
import { useNavigate } from 'react-router-dom';
import { loginPage } from '../../store/fixed-routing';
import ManagedFormContext from '../../ui/Form/MangedForm';
import { TextFormField } from './NewSchoolDialog';
import ManagedFormSelectField from '../../ui/Form/ManagedFormSelectField';
import {getDistricts, getSchools, getSpecializations} from '../../api/user-api';
import Student from "../../model/Student";
import District from "../../model/District";
import Loader from "../../ui/Loader";
import ManagedFormField from "../../ui/Form/ManagedFormField";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import dayjs, {Dayjs} from "dayjs";
import {useCustomTheme} from "../../store/theme-context";

type Props = {
	student: Student | null | undefined;
	open: boolean;
	onClose: () => void;
};
const EditStudentDialog = ({ student, open, onClose }: Props) => {
	const [isLoading, setIsLoading] = useState(true);
	const { palette, typography } = useTheme();
	const { isHighContrast } = useCustomTheme();
	const { token, logout } = useContext(AuthContext);
	const navigate = useNavigate();
	const { setMsg } = useContext(SnackbarContext);
	const { reset, validate, getFormData } = useContext(ManagedFormContext);
	const [sendingRequest, setSendingRequest] = useState(false);
	const [specializations, setSpecializations] = useState<any>();
	const [districts, setDistricts] = useState<District[]>([]);
	const [schools, setSchools] = useState<any>();
	const [validated, setValidated] = useState(false);
	const [schoolNames, setSchoolNames] = useState<any>([]);
	const [birthdate, setBirthDate] = useState(null as Dayjs | null);
	const [specialCertificate, setSpecialCertificate] = useState<boolean | undefined>(false);
	const [selectSchool, setSelectSchool] = useState('');

	useEffect(() => {
		const fetchData = async () => {
			const specializations = await getSpecializations();
			const schools = await getSchools();
			const districts = await getDistricts();
			setSpecializations(specializations);
			setSchools(schools);

			if (specializations) {
				setSpecializations(specializations.map((s: any) => ({ label: s.name, value: s.id })));
			}

			if (districts) {
				setDistricts(districts.map((d: any) => District.fromApiResponse(d)));
			}

			if (schools) {
				const schoolList = schools
					.sort((a: any, b: any) => {
						if (a.city < b.city) {
							return -1;
						}
						if (a.city > b.city) {
							return 1;
						}
						return 0;
					})
					.map((school: any) => school.city.toUpperCase() + ', ' + school.name.toUpperCase());
				setSchoolNames(Array.from(new Set(schoolList)));
			}
			setIsLoading(false);
		};
		fetchData().catch((error) => {
			console.log(error)
			setIsLoading(false);
		});

		const schoolName = student?.school.city.toUpperCase() + ', ' + student?.school.name.toUpperCase();
		setSelectSchool(schoolName)
		setSpecialCertificate(student?.specialCertificate)
	}, [student]);

	const handleApprove = () => {
		const formData = getFormData();

		const getSchoolId = schools.find((school: any) => {
			return school.city.toUpperCase() + ', ' + school.name.toUpperCase() === selectSchool;
		});

		formData.id = student?.id
		if(getSchoolId) {
			formData.schoolId = getSchoolId.id
		}
		if(!birthdate && !student?.birthdate) {
			setMsg({ msg: 'Pole data urodzenia jest nieprawidłowa', severity: 'warning' });
			return;
		}
		formData.birthday = birthdate ? birthdate : student?.birthdate
		formData.specialCertificate = specialCertificate

		const validationResult = validate();
		if (validationResult) {
			setMsg({ msg: validationResult, severity: 'warning' });
			return;
		}
		setSendingRequest(true);
		if (sendingRequest) {
			return;
		}

		updateStudent(token, formData)
			.then((response) => {
				if (response.status === 200) {
					setMsg({ msg: 'Dane zostały zaktualizowane', severity: 'success' });
					window.location.reload();
				} else {
					setMsg({ msg: response.data });
				}
				setSendingRequest(false);
			})
			.catch((exception) => {
				console.error(exception);
				if (exception.response.status === 401 || exception.response.status === 403) {
					logout();
					navigate(loginPage);
					setMsg({ msg: 'Nie masz uprawnień albo Twoja sesja wygasła' });
				} else if (exception?.response?.data?.message) {
					setMsg({ msg: exception?.response?.data?.message });
				}
				setSendingRequest(false);
			});
	};

	const birthdayHandler = (value: Dayjs | null) => {
		setBirthDate(value);
	};

	const schoolHandler = (e: any) => {
		setSelectSchool(e.target.innerText)
	}

	const specialCertificateHandler = (value: any) => {
		setSpecialCertificate(value.target.checked)
	};

	return (
		<>
			<Dialog
				open={open}
				onClose={() => {
					reset();
					onClose();
				}}
			>
				<DialogTitle sx={{ fontSize: '24px' }}>Edytuj ucznia</DialogTitle>
				{isLoading ? <Loader /> : student && districts && schools &&
	                <DialogContent>
						<Grid
							item
							container
							justifyContent={'space-between'}
						>
							<ManagedFormSelectField
								label={'Tytuł'}
								accessor={'personalTitle'}
								initialValue={student.personalTitle}
								options={[
									{ label: 'Pan', value: 'Pan' },
									{ label: 'Pani', value: 'Pani' },
								]}
								formControlProps={{
									sx: {
										width: '100%',
										my: '10px',
									},
								}}
								menuItemProps={{
									sx: { fontSize: '16px' },
								}}
                                initialCorrect={true}
							/>
							<TextFormField
								label={'Imię'}
								accessor={'firstName'}
								initialValue={student.firstName}
                                initialCorrect={true}
							/>
							<TextFormField
								label={'Drugie imię'}
								accessor={'secondName'}
								initialValue={student.secondName}
                                initialCorrect={true}
							/>
							<TextFormField
								label={'Nazwisko'}
								accessor={'lastName'}
								initialValue={student.lastName}
                                initialCorrect={true}
							/>
	                        <TextFormField
	                            label={'Imię i nazwisko rodzica'}
	                            accessor={'parentName'}
	                            initialValue={student.parentName}
                                initialCorrect={true}
	                        />
	                        <TextFormField
	                            label={'Email rodzica'}
                                type={'email'}
	                            accessor={'parentEmail'}
	                            initialValue={student.parentEmail}
                                initialCorrect={true}
	                        />
							<TextFormField
								type={'phone'}
								label={'Telefon'}
								accessor={'phone'}
								initialValue={student.phone}
                                initialCorrect={true}
							/>
							<TextFormField
								type={'email'}
								label={'Email'}
								accessor={'email'}
								initialValue={student.email}
                                initialCorrect={true}
							/>
							<TextFormField
								min={1}
								type={'number'}
								label={'Klasa'}
								accessor={'classNumber'}
								initialValue={student.schoolClass}
                                initialCorrect={true}
							/>
							<ManagedFormSelectField
								label={'Okręg'}
								accessor={'districtId'}
								initialValue={student.district.id}
								options={districts.map((d) => ({ label: d.name, value: d.id }))}
								formControlProps={{
									sx: {
										width: '100%',
										my: '10px',
									},
								}}
								menuItemProps={{
									sx: { fontSize: '16px' },
								}}
                                initialCorrect={true}
							/>
							<ManagedFormSelectField
								label={'Specjalizacja'}
								accessor={'specializationId'}
								initialValue={student.specialization.id}
								options={specializations}
								formControlProps={{
									sx: {
										width: '100%',
										my: '10px',
									},
								}}
								menuItemProps={{
									sx: { fontSize: '16px' },
								}}
                                initialCorrect={true}
							/>
							<Autocomplete
		                        disablePortal
		                        options={schoolNames}
		                        sx={{width: '100%', my: '10px'}}
                                defaultValue={selectSchool}
		                        onChange={schoolHandler}
		                        renderInput={(params) =>
			                        <ManagedFormField {...params} accessor={'schoolId'} label={'Szkoła'} initialCorrect={true} />
								}
		                    />
							<TextFormField
								multiline
								rows={5}
								max={255}
								fullWidth
								label={'Notatka'}
								accessor={'notes'}
								initialValue={student.notes}
                                initialCorrect={true}
							/>
						</Grid>
                        <Typography>Adres</Typography>
	                    <Grid
	                        item
	                        container
	                        justifyContent={'space-between'}
	                    >
	                        <TextFormField
	                            label={'Ulica'}
	                            accessor={'street'}
	                            fullWidth
	                            initialValue={student.street}
                                initialCorrect={true}
	                        />
	                        <TextFormField
	                            label={'Numer budynku'}
	                            accessor={'building'}
	                            initialValue={student.building}
                                initialCorrect={true}
	                        />
	                        <TextFormField
	                            label={'Numer mieszkania'}
	                            accessor={'apartment'}
	                            initialValue={student.apartment}
                                initialCorrect={true}
	                        />
	                        <TextFormField
	                            label={'Kod pocztowy'}
	                            type={'zipCode'}
	                            accessor={'zipCode'}
	                            initialValue={student.zipCode}
                                initialCorrect={true}
	                        />
	                        <TextFormField
	                            label={'Miasto'}
	                            accessor={'city'}
	                            initialValue={student.city}
                                initialCorrect={true}
	                        />
						</Grid>
                        <Typography>Dane do certyfikatu</Typography>
	                        <Grid
		                        item
		                        container
		                        justifyContent={'space-between'}
	                        >
		                        <TextFormField
		                            label={'Certyfikat - email'}
		                            accessor={'certEmail'}
		                            fullWidth
		                            initialValue={student.certEmail}
		                            optional={true}
		                        />
		                        <TextFormField
		                            label={'Certyfikat - ulica'}
		                            accessor={'certStreet'}
		                            initialValue={student.certStreet}
		                            optional={true}
		                        />
		                        <TextFormField
		                            label={'Certyfikat - budynek'}
		                            accessor={'certBuilding'}
		                            initialValue={student.certBuilding}
		                            optional={true}
		                        />
		                        <TextFormField
		                            label={'Certyfikat - numer mieszkania'}
		                            accessor={'certApartment'}
		                            initialValue={student.certApartment}
		                            optional={true}
		                        />
	                            <TextFormField
	                                label={'Certyfikat - kod pocztowy'}
                                    type={'zipCode'}
	                                accessor={'certZipCode'}
	                                initialValue={student.certZipCode}
	                                optional={true}
	                            />
	                            <TextFormField
	                                label={'Miejsce urodzenia'}
	                                accessor={'birthdayPlace'}
	                                initialValue={student.birthdatePlace ? student.birthdatePlace : ''}
	                                optional={true}
	                            />
                                <Box sx={{width: '100%'}}>
		                            <LocalizationProvider
		                                dateAdapter={AdapterDayjs}
		                                adapterLocale='pl'
		                            >
                                        <Typography>Data urodzenia</Typography>
		                                <DatePicker
		                                    label=''
		                                    value={dayjs(student.birthdate)}
		                                    maxDate={dayjs()}
		                                    minDate={dayjs('2000-01-01')}
		                                    slotProps={{textField: {size: 'small', error: validated && !student.birthdate}}}
		                                    onChange={birthdayHandler}
		                                    sx={{
					                            width: '100%',
					                            borderRadius: '4px',
					                            '& div, & input, & fieldset': {
						                            borderRadius: '4px',
					                            },
				                            }}
		                                />
									</LocalizationProvider>
                                </Box>
	                            <Box
	                                mt={3}
	                                sx={{
				                        '& span': {
					                        fontSize: typography.subtitle2.fontSize,
					                        fontWeight: '500',
				                        },
			                        }}
	                            >
	                                <FormControlLabel
	                                    control={
											<Checkbox
												sx={{
													borderRadius: 3,
													width: 16,
													height: 16,
													mr: '6px',
													color: isHighContrast ? palette.primary.light : 'inherit',
													'& span': {
														color: isHighContrast ? palette.primary.light : 'inherit',
													}
												}}
												checked={specialCertificate}
											/>
										}
	                                    label='Posiadam orzeczenie lub opinie o dysgrafii'
	                                    onChange={specialCertificateHandler}
	                                    sx={{
											ml: '2px',
		                                    '& span': {
			                                    color: isHighContrast ? palette.primary.light + '!important' : 'inherit',
		                                    }
	                                    }}
	                                />
								</Box>
							</Grid>
					</DialogContent>
				}
				{!isLoading && <DialogActions>
					<Button
						sx={{
							color: isHighContrast ? palette.primary.light : palette.secondary.main,
						}}
						onClick={() => {
							onClose();
							setTimeout(() => reset(), 500);
						}}
					>
						Anuluj
					</Button>
					<Button
						sx={{
							color: isHighContrast ? palette.primary.light : palette.success.main,
						}}
						onClick={() => handleApprove()}
					>
						{sendingRequest ? <CircularProgress /> : 'Zapisz'}
					</Button>
				</DialogActions>}
			</Dialog>
		</>
	);
};

export default EditStudentDialog;
