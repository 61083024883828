import { Box, Link, Typography, useTheme } from '@mui/material';
import BlockWrapper from '../components/BlockWrapper';
import Wysiwyg from '../components/UI/Wysiwyg';
import LargeLeaf from '../ui/icons/LargeLeaf';

type Props = {
	content: any;
};

const HeaderWithLeaf = ({ content }: Props) => {
	const { breakpoints, palette, typography } = useTheme();

	return (
		<BlockWrapper
			justify='flex-start'
			lgSize={12}
			withPadding={false}
		>
			<Box
				display={'flex'}
				flexDirection={'column'}
				gap={'16px'}
				mt={'20px'}
				mb={'63px'}
				ml={'40px'}
				width={'70%'}
				sx={{
					[breakpoints.down('sm')]: {
						width: '100%',
						mr: '40px',
					},
				}}
			>
				{content.header_title && <Typography
					component={'span'}
					variant={'title'}
					sx={{
						color: palette.primary.main,
						fontWeight: 500,
					}}
				>
					{content.header_title && content.header_title}
				</Typography>}
				{content.header_text && <Wysiwyg
					text={content.header_text && content.header_text}
					fontWeight={'400'}
					sx={{
						fontSize: typography.h5.fontSize,
					}}
				/>}
			</Box>
			<Box
				component={'section'}
				sx={{
					backgroundColor: content.background_color && content.background_color,
					display: 'flex',
					alignItems: 'center',
					position: 'relative',
					width: '100%',
					p: '61px 202px 61px 40px',
					[breakpoints.down('sm')]: {
						pl: '20px',
						pr: '160px',
					},
				}}
			>
				<Box
					display={'flex'}
					alignItems={'center'}
					justifyContent={'flex-end'}
				>
					<Typography
						component={'span'}
						variant={'h1'}
						sx={{
							display: 'inline-block',
							color: content.font_color && content.font_color,
							fontWeight: 500,
						}}
					>
						{content.text && content.text}
						{content.link && (
							<>
								{' '}
								<Link
									href={content.link.url}
									target={content.link.target}
									title={content.link.title}
									sx={{
										display: 'inline-block',
										color: content.font_color && content.font_color,
										textDecorationColor: content.font_color && content.font_color,
										textUnderlineOffset: '5px',
									}}
								>
									{content.link.title}
								</Link>
							</>
						)}
					</Typography>
				</Box>
				<Box
					component={'aside'}
					position={'absolute'}
					right={'41px'}
					bottom={'-26px'}
					sx={{
						[breakpoints.down('sm')]: {
							right: '0px',
							bottom: '-70px',
							'& svg': {
								width: '80%',
							},
						},
					}}
				>
					<LargeLeaf color={content.leaf_color ? content.leaf_color : 'transparent'} />
				</Box>
			</Box>
		</BlockWrapper>
	);
};

export default HeaderWithLeaf;
