import dayjs, { Dayjs } from 'dayjs';
import { StageOption } from '../components/AdminPanel/StagesTable';
import { STAGE_0, STAGE_1, STAGE_2, STAGE_3, STAGE_4, STAGE_5 } from '../utils/stages';
import { romanize } from '../utils/string-tools';

class Olympics {
	edition: string;
	schoolYear: string;
	teachersRegistrationFinishDate: Dayjs | null;
	studentsRegistrationFinishDate: Dayjs | null;
	registrationConfirmationFinishDate: Dayjs | null;
	firstStagePublished: boolean;
	secondAStagePublished: boolean;
	secondBStagePublished: boolean;
	thirdAStagePublished: boolean;
	thirdBStagePublished: boolean;

	secondStagePoints: {
		partA: {
			v1: {
				part1: number;
				part2: number;
			};
			v2: {
				part1: number;
				part2: number;
			};
			total: number;
		};
		partB: {
			part1: number;
			part2: number;
			part3: number;
			total: number;
			totalStudentsLimit: number;
			stageClosedDate: Dayjs | null;
			stageClosed: boolean;
		};
	};
	thirdStagePoints: {
		partA: {
			points: number;
			finalistPoints: number;
		};
		partB: {
			laureate1: number;
			laureate2: number;
			laureate3: number;
		};
	};

	constructor(
		edition: string,
		schoolYear: string,
		teachersRegistrationFinishDate: dayjs.Dayjs | null,
		studentsRegistrationFinishDate: dayjs.Dayjs | null,
		registrationConfirmationFinishDate: dayjs.Dayjs | null,
		firstStagePublished: boolean,
		secondAStagePublished: boolean,
		secondBStagePublished: boolean,
		thirdAStagePublished: boolean,
		thirdBStagePublished: boolean,
		secondStagePoints: {
			partA: { v1: { part1: number; part2: number }; v2: { part1: number; part2: number }; total: number };
			partB: {
				part1: number;
				part2: number;
				part3: number;
				total: number;
				totalStudentsLimit: number;
				stageClosedDate: dayjs.Dayjs | null;
				stageClosed: boolean;
			};
		},
		thirdStagePoints: {
			partA: { points: number; finalistPoints: number };
			partB: { laureate1: number; laureate2: number; laureate3: number };
		},
	) {
		this.edition = edition;
		this.schoolYear = schoolYear;
		this.teachersRegistrationFinishDate = teachersRegistrationFinishDate;
		this.studentsRegistrationFinishDate = studentsRegistrationFinishDate;
		this.registrationConfirmationFinishDate = registrationConfirmationFinishDate;
		this.firstStagePublished = firstStagePublished;
		this.secondAStagePublished = secondAStagePublished;
		this.secondBStagePublished = secondBStagePublished;
		this.thirdAStagePublished = thirdAStagePublished;
		this.thirdBStagePublished = thirdBStagePublished;
		this.secondStagePoints = secondStagePoints;
		this.thirdStagePoints = thirdStagePoints;
	}

	public static fromApiResponse(data: any) {
		return new Olympics(
			romanize(data['edition']),
			data['school_year'],
			dayjs(data['teacher_registration_finish_date']),
			dayjs(data['students_registration_finish_date']),
			dayjs(data['registration_confirmation_finish_date']),
			data['first_stage_published'] === 1,
			data['second_a_stage_published'] === 1,
			data['second_b_stage_published'] === 1,
			data['third_a_stage_published'] === 1,
			data['third_b_stage_published'] === 1,
			{
				partA: {
					v1: {
						part1: data['second_a_one_stage_points'] ? data['second_a_one_stage_points'] : 0,
						part2: data['second_a_two_stage_points'] ? data['second_a_two_stage_points'] : 0,
					},
					v2: {
						part1: data['second_a_one_alt_stage_points'] ? data['second_a_one_alt_stage_points'] : 0,
						part2: data['second_a_two_alt_stage_points'] ? data['second_a_two_alt_stage_points'] : 0,
					},
					total: data['second_a_total_stage_points'] ? data['second_a_total_stage_points'] : 0,
				},
				partB: {
					part1: data['second_b_one_stage_points'] ? data['second_b_one_stage_points'] : 0,
					part2: data['second_b_two_stage_points'] ? data['second_b_two_stage_points'] : 0,
					part3: data['second_b_three_stage_points'] ? data['second_b_three_stage_points'] : 0,
					total: data['second_b_total_stage_points'] ? data['second_b_total_stage_points'] : 0,
					totalStudentsLimit: data['second_b_total_students_limit'] ? data['second_b_total_students_limit'] : 0,
					stageClosedDate: dayjs(data['second_b_end_date']),
					stageClosed: Olympics.isStageClosed(data['second_b_end_date']),
				},
			},
			{
				partA: {
					points: data['third_a_stage_points'] ? data['third_a_stage_points'] : 0,
					finalistPoints: data['finalists_points'] ? data['finalists_points'] : 0,
				},
				partB: {
					laureate1: data['laureate_one_points'] ? data['laureate_one_points'] : 0,
					laureate2: data['laureate_two_points'] ? data['laureate_two_points'] : 0,
					laureate3: data['laureate_three_points'] ? data['laureate_three_points'] : 0,
				},
			},
		);
	}

	public getPublishedStage = (): StageOption => {
		if (this.thirdBStagePublished) {
			return STAGE_5;
		}
		if (this.thirdAStagePublished) {
			return STAGE_4;
		}
		if (this.secondBStagePublished) {
			return STAGE_3;
		}
		if (this.secondAStagePublished) {
			return STAGE_2;
		}
		if (this.firstStagePublished) {
			return STAGE_1;
		}
		return STAGE_0;
	};

	private static isStageClosed = (endTimestamp: string): boolean => {
		const timestamp = new Date(endTimestamp).valueOf();
		if (isNaN(timestamp.valueOf())) {
			return true;
		} else {
			return Date.now() > timestamp;
		}
	};
}

export default Olympics;
