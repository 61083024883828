import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	useTheme,
} from '@mui/material';
import { addAdditionalStudent } from '../../api/content-api';
import { useContext, useEffect, useState } from 'react';
import AuthContext from '../../store/auth-context';
import SnackbarContext from '../../store/snackbar-context';
import { useNavigate } from 'react-router-dom';
import { loginPage } from '../../store/fixed-routing';
import ManagedFormContext from '../../ui/Form/MangedForm';
import { TextFormField } from './NewSchoolDialog';
import ManagedFormSelectField from '../../ui/Form/ManagedFormSelectField';
import { getSpecializations } from '../../api/user-api';
import {useCustomTheme} from "../../store/theme-context";

type Props = {
	open: boolean;
	onClose: () => void;
};
const NewStudentDialog = ({ open, onClose }: Props) => {
	const { palette } = useTheme();
	const { isHighContrast } = useCustomTheme();
	const { token, logout } = useContext(AuthContext);
	const navigate = useNavigate();
	const { setMsg } = useContext(SnackbarContext);
	const { reset, validate, getFormData } = useContext(ManagedFormContext);
	const [sendingRequest, setSendingRequest] = useState(false);
	const [specializations, setSpecializations] = useState<any>();

	useEffect(() => {
		const fetchData = async () => {
			const specializations = await getSpecializations();
			setSpecializations(specializations);

			if (specializations) {
				setSpecializations(specializations.map((s: any) => ({ label: s.name, value: s.id })));
			}
		};
		fetchData().catch((error) => console.log(error));
	}, []);

	const handleApprove = () => {
		const validationResult = validate();
		if (validationResult) {
			setMsg({ msg: validationResult, severity: 'warning' });
			return;
		}
		setSendingRequest(true);
		if (sendingRequest) {
			return;
		}
		const formData = getFormData();
		addAdditionalStudent(token, formData)
			.then((response) => {
				if (response.status === 200) {
					setMsg({ msg: 'Dane zostały zaktualizowane', severity: 'success' });
					window.location.reload();
				} else {
					setMsg({ msg: response.data });
				}
				setSendingRequest(false);
			})
			.catch((exception) => {
				console.error(exception);
				if (exception.response.status === 401 || exception.response.status === 403) {
					logout();
					navigate(loginPage);
					setMsg({ msg: 'Nie masz uprawnień albo Twoja sesja wygasła' });
				} else if (exception?.response?.data?.message) {
					setMsg({ msg: exception?.response?.data?.message });
				}
				setSendingRequest(false);
			});
	};

	return (
		<>
			<Dialog
				open={open}
				onClose={() => {
					reset();
					onClose();
				}}
			>
				<DialogTitle sx={{ fontSize: '24px' }}>Dodaj nowego ucznia</DialogTitle>
				<DialogContent>
					<Grid
						item
						container
						justifyContent={'space-between'}
					>
						<ManagedFormSelectField
							label={'Tytuł'}
							accessor={'personalTitle'}
							options={[
								{ label: 'Pan', value: 'Pan' },
								{ label: 'Pani', value: 'Pani' },
							]}
							formControlProps={{
								sx: {
									width: '100%',
									my: '10px',
								},
							}}
							menuItemProps={{
								sx: { fontSize: '16px' },
							}}
						/>
						<TextFormField
							label={'Imię'}
							accessor={'firstName'}
						/>
						<TextFormField
							label={'Drugie imię'}
							accessor={'secondName'}
						/>
						<TextFormField
							label={'Nazwisko'}
							accessor={'lastName'}
						/>
						<TextFormField
							type={'phone'}
							label={'Telefon'}
							accessor={'phone'}
						/>
						<TextFormField
							type={'email'}
							label={'Email'}
							accessor={'email'}
						/>
						<TextFormField
							min={1}
							type={'number'}
							label={'Klasa'}
							accessor={'classNumber'}
						/>
						<ManagedFormSelectField
							label={'Specjalizacja'}
							accessor={'specializationId'}
							options={specializations}
							formControlProps={{
								sx: {
									width: '100%',
									my: '10px',
								},
							}}
							menuItemProps={{
								sx: { fontSize: '16px' },
							}}
						/>
						<TextFormField
							multiline
							rows={5}
							max={255}
							fullWidth
							label={'Notatka'}
							accessor={'notes'}
						/>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button
						sx={{
							color: isHighContrast ? palette.primary.light : palette.secondary.main,
						}}
						onClick={() => {
							onClose();
							setTimeout(() => reset(), 500);
						}}
					>
						Anuluj
					</Button>
					<Button
						sx={{
							color: isHighContrast ? palette.primary.light : palette.success.main,
						}}
						onClick={() => handleApprove()}
					>
						{sendingRequest ? <CircularProgress /> : 'Zapisz'}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default NewStudentDialog;
