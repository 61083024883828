import Menu from '../model/Menu';
import Settings from '../model/Settings';
import Page from '../model/Page';
import axios from 'axios';
import Student from '../model/Student';
import Olympics from '../model/Olympics';
import Post from '../model/Post';

export async function getAllPages() {
	const pagesRes = await fetch(process.env.REACT_APP_API_URL + '/oo/pages');
	const data = await pagesRes.json();
	return Page.fromApiResponse(data);
}

export async function getAllPosts() {
	const pagesRes = await fetch(process.env.REACT_APP_API_URL + '/oo/posts');
	const data = await pagesRes.json();
	return Post.fromApiResponse(data);
}

export async function getSettings() {
	const settingsRes = await fetch(process.env.REACT_APP_API_URL + '/acf/v3/options/option?_embed');
	const data = await settingsRes.json();
	return Settings.fromApiResponse(data);
}

export const getMenu = async () => {
	const menuRes = await fetch(process.env.REACT_APP_API_URL + '/oo/menu?location=topbar');
	const data = await menuRes.json();
	return Menu.fromApiResponse(data);
};

export async function getPostsPerPage(perPage: number, page: number) {
	const postsRes = await fetch(process.env.REACT_APP_API_URL + '/oo/posts-list?per_page=' + perPage + '&page=' + page);
	return await postsRes.json();
}

export async function getAnnouncementsPerPage(perPage: number, page: number) {
	const announcementsRes = await fetch(
		process.env.REACT_APP_API_URL + '/oo/announcements?per_page=' + perPage + '&page=' + page,
	);
	return await announcementsRes.json();
}

export const getAllDistricts = async () => {
	const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/olympics/districts' : '';
	return await axios.get(url);
};

export const getAllSchools = async () => {
	const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/olympics/schools' : '';
	return await axios.get(url);
};

export const addSchool = async (token: string | null, data: any) => {
	const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/olympics/schools' : '';
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return await axios.post(url, data, config);
};

export const addAdditionalStudent = async (token: string | null, data: any) => {
	const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/registration/students/additional' : '';
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return await axios.post(url, data, config);
};

export const updateStudent = async (token: string | null, data: any) => {
	const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/students/data-update' : '';
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return await axios.put(url, data, config);
};

export const getAllTeachers = async (token: string | null) => {
	const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/teachers/all' : '';
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return await axios.get(url, config);
};

export const sendTeacherApproval = async (token: string | null, teacherId: number | undefined) => {
	const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/teachers/approve' : '';
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return await axios.post(url, { teacherId }, config);
};

export const getAllStudents = async (token: string | null) => {
	const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/students/all' : '';
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return await axios.get(url, config);
};

export const updateFirstStageResult = async (token: string | null, data: any) => {
	const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/students/first-stage' : '';
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return await axios.put(url, data, config);
};

export const updateSecondAStageResult = async (token: string | null, data: any) => {
	const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/students/second-a-stage' : '';
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return await axios.put(url, data, config);
};

export const updateSecondBStageResult = async (token: string | null, data: any) => {
	const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/students/second-b-stage' : '';
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return await axios.put(url, data, config);
};

export const updateThirdAStageResult = async (token: string | null, data: any) => {
	const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/students/third-a-stage' : '';
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return await axios.put(url, data, config);
};

export const updateThirdBStageResult = async (token: string | null, data: any) => {
	const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/students/third-b-stage' : '';
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return await axios.put(url, data, config);
};

export const getFirstStageStudents = async (token: string | null) => {
	const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/students/first-stage' : '';
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return await axios.get(url, config);
};

export const getSecondAStageStudents = async (token: string | null) => {
	const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/students/second-a-stage' : '';
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return await axios.get(url, config);
};

export const getSecondBStageStudents = async (token: string | null) => {
	const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/students/second-b-stage' : '';
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return await axios.get(url, config);
};

export const getThirdAStageStudents = async (token: string | null) => {
	const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/students/third-a-stage' : '';
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return await axios.get(url, config);
};

export const getThirdBStageStudents = async (token: string | null) => {
	const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/students/third-b-stage' : '';
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return await axios.get(url, config);
};

export const getTeacherData = async (token: string | null, teacherId: number | null) => {
	const params = teacherId ? `?teacherId=${teacherId}` : '';
	const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/teachers/data' + params : '';
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return await axios.get(url, config);
};

export const updateTeacherData = async (token: string | null, data: any) => {
	const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/teachers/update' : '';
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return await axios.put(url, data, config);
};

export const getApprovedTeachers = async (token: string | null) => {
	const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/teachers/filter' : '';
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return await axios.get(url, config);
};

export const sendStudentUpdate = async (token: string | null, student?: Student | null) => {
	const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/students/update' : '';
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	const requestBody = {
		id: student?.id,
		approved: student?.approved,
		teachersIds: student?.teachers ? student?.teachers.map((t) => t.id) : [],
	};
	return await axios.put(url, requestBody, config);
};

export const getOlympicsSettings = async (token: string | null) => {
	const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/olympics/settings' : '';
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return await axios.get(url, config);
};

export const getRegistrationSettings = async () => {
	const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/olympics/registration-settings' : '';
	return await axios.get(url);
};

export const updateOlympicsSettings = async (token: string | null, data: Olympics) => {
	const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/olympics/settings' : '';
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return await axios.put(url, data, config);
};

export const publishStage = async (token: string | null, stage: number) => {
	const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/olympics/stage' : '';
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return await axios.put(url, { stage }, config);
};

export const downloadReport = async (token: string | null, type: string) => {
	const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + `/olympics/report?type=${type}` : '';
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		responseType: 'blob',
	};
	// @ts-ignore
	return await axios.get(url, config);
};

export const getResetToken = async (token: string | null) => {
	const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/olympics/reset-token' : '';
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return await axios.get(url, config);
};

export const resetOlympics = async (token: string | null, confirmationToken: string | null) => {
	const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/olympics/reset' : '';
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return await axios.post(url, { confirmationToken }, config);
};

export const createCertificates = async (token: string | null, data: {type: string}) => {
	const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/certificates/create' : '';
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return await axios.post(url, data, config);
};
