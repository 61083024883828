import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
	Typography,
	useTheme,
} from '@mui/material';
import { getResetToken, resetOlympics } from '../../api/content-api';
import { loginPage } from '../../store/fixed-routing';
import { useContext, useEffect, useState } from 'react';
import AuthContext from '../../store/auth-context';
import SnackbarContext from '../../store/snackbar-context';
import { useNavigate } from 'react-router-dom';
import Olympics from '../../model/Olympics';
import OlympicsContext from '../../store/olympics-context';
import {useCustomTheme} from "../../store/theme-context";

type Props = {
	open: boolean;
	close: () => void;
	onSuccess: () => void;
};
const NewOlympicsDialog = ({ open, close, onSuccess }: Props) => {
	const { palette } = useTheme();
	const { isHighContrast } = useCustomTheme();
	const { token, logout } = useContext(AuthContext);
	const { setOlympics } = useContext(OlympicsContext);
	const [resetToken, setResetToken] = useState<any>();
	const [confirmationToken, setConfirmationToken] = useState<string>('');
	const navigate = useNavigate();
	const { setMsg } = useContext(SnackbarContext);
	const [sendingRequest, setSendingRequest] = useState(false);

	useEffect(() => {
		if (open) {
			getResetToken(token)
				.then((response) => {
					if (response.status === 200) {
						setResetToken(response.data);
					} else {
						setMsg({ msg: response.data });
					}
				})
				.catch((exception) => {
					console.error(exception);
					if (exception.response.status === 401 || exception.response.status === 403) {
						logout();
						navigate(loginPage);
						setMsg({ msg: 'Nie masz uprawnień albo Twoja sesja wygasła' });
					} else {
						setMsg({ msg: 'Błąd serwera. Spróbuj za chwilę' });
					}
				});
		} else {
			setResetToken(null);
			setConfirmationToken('');
		}
	}, [open]);
	const handleApprove = () => {
		if (confirmationToken !== resetToken?.ui_token) {
			setMsg({ msg: 'Niepoprawny kod', severity: 'error' });
			return;
		}
		setSendingRequest(true);
		if (sendingRequest) {
			return;
		}
		resetOlympics(token, resetToken.token)
			.then((response) => {
				if (response.status === 200) {
					setOlympics(Olympics.fromApiResponse(response.data));
					close();
					setMsg({
						msg: 'Nowa edycja olimpiady została utworzona. Uzupełnij ustawienia olimpiady',
						severity: 'success',
					});
				} else {
					setMsg({ msg: response.data });
				}
				setSendingRequest(false);
			})
			.catch((exception) => {
				console.error(exception);
				if (exception.response.status === 401 || exception.response.status === 403) {
					logout();
					navigate(loginPage);
					setMsg({ msg: 'Nie masz uprawnień albo Twoja sesja wygasła' });
				} else {
					const msg = exception?.response?.data?.message
						? exception?.response?.data?.message
						: 'Błąd serwera. Spróbuj za chwilę';
					setMsg({ msg });
				}
				setSendingRequest(false);
			});
	};

	return (
		<>
			<Dialog
				open={open}
				onClose={() => close()}
			>
				<DialogTitle sx={{ fontSize: '24px' }}>Czy napewno chcesz stworzyć nową edycję olimpiady?</DialogTitle>
				<DialogContent>
					<Typography sx={{ fontSize: '16px', color: isHighContrast ? palette.primary.light : 'red' }}>
						Upewnij się, że excel został pobrany dla wszystkich danych.
					</Typography>
					<Typography sx={{ fontSize: '16px' }}>Ten proces może zająć dłuższą chwilę...</Typography>
					<Typography sx={{ fontSize: '20px', mt: '50px' }}>
						Przepisz kod aby kontynuować: {resetToken?.ui_token}
					</Typography>
					<TextField
						value={confirmationToken}
						onChange={(event) => setConfirmationToken(event.target.value)}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						sx={{
							color: isHighContrast ? palette.primary.light : palette.secondary.main,
						}}
						onClick={() => close()}
					>
						Anuluj
					</Button>
					<Button
						sx={{
							color: isHighContrast ? palette.primary.light : palette.success.main,
						}}
						onClick={() => handleApprove()}
					>
						{sendingRequest ? <CircularProgress /> : 'OK'}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default NewOlympicsDialog;
